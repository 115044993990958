.home {
	#header {
	    margin-bottom: 10px;
	}

	#content {
		.box-product-category,
		.box-post{
			margin-bottom: 12px;
			padding: 0;
		}

		.box-product-category{
			.title{
				border: none;
			}

			.box {
				.bg-box{
					border: none !important;
				}
			}
		}

		.box-post {
			border: none;
			margin-bottom: 0;
		}

		.box-product {
			.box {
				width: 33.3333%;
			}
		}

		.box-gear{
			padding: 0 10px;
			.box{
				margin-left: 0;
				margin-right: 0;
				border: none;
				.bg-box{
					border: 1px solid #eeeeee;
					border-radius: 15px;
				}
			}
			.box:hover{
				.bg-box{
					border-color: #0070BA;
				}
			}
		}
	}
}

.no-home {
	.slideshow {
		margin-bottom: 15px;
	}

	#content {
		.title{
			background-color: $background_title;
			border: 1px solid $border-color-normal;
			border-bottom: none;
			padding-left: 10px;
			margin: 0 0 -1px 0;
			display: inline-block;
			width: 100%;
			color: $text-color-normal;

			h1, span {
				line-height: 40px;
				font-size: 24px !important;
				margin: 0;
				font-weight: normal;
			}

			&.blue {
				background: $primary-color!important;
				color: white;
				border-color: $primary-color!important;
				margin: 0 0 -7px 0;
				padding: 0 0 0 10px!important;
				font-weight: normal!important;

				a {
					color: white;

					&:hover {
						text-decoration: none;

						i {
							background-position: -133px -185px;
						}
					}

					i {
						padding: 3px 10px;
						background: url('img/icon.png') -131px -185px no-repeat;
						margin-left: 3px;
					}
				}
			}
		}
	}
}

.owl-theme {
	.owl-nav {
		[class*=owl-] {
			font-size: 25px!important;
			margin: 5px!important;
			padding: 4px 7px!important;
			background: none!important;
			border-radius: 0!important;
			color: $primary-color!important;
			position: absolute;

			&:hover {
				.fa {
					color: darken($primary-color, 10%);
				}
			}
		}

		.owl-prev {
			left: -40px;
			top: 37px;

			@media screen and (max-width: 375px) {
				left: -30px;
			}
		}

		.owl-next {
			right: -40px;
			top: 37px;

			@media screen and (max-width: 375px) {
				right: -30px;
			}
		}
	}
}