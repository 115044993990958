.captcha {
    position: relative;
    overflow: hidden;

    label {
        margin-top: 0;
    }

    .help-block {
        color: red;
        margin-left: 10px;
    }

    .input {
        margin-bottom: 10px;
    }

    .creload {
        text-indent: -1000px;
        overflow: hidden;
        display: block;
        width: 20px;
        height: 20px;
        background-position: -158px -2px;
        background-repeat:  no-repeat;
        position: absolute;
        top: 46px;
        left: 178px;
    }

    #img_captcha {
        position: relative;
        height: 70px;
        display: inline-block;
    }
}