/*** popup ****/
#mask {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
    background-color: #000;
    display: none;
    opacity: 0.8;
}
#popup .small{
    font-size: 100% !important;
}
#popup {

    .window {
        position: absolute;
        left: 0;
        top: 100px;
        width: 820px;
        display: none;
        z-index: 9999;
        color: #fff;
        width: 500px;
        text-align: justify;
        z-index: 11000;
    }

    .notice{
        color: red;
        margin-bottom: 7px;
        text-align: center;
    }

    a{
        color: #333333;
    }

    #popup_cart {
        background: #eee;
        height: 605px;
        width: 700px !important;
        color: #333;
        border-radius: 2px;
        overflow: hidden;

        span.title {
            color: #fff;
            font-weight: bold;
            font-size: 15px;
            line-height: 32px;
            background: $primary-color;
            display: block;
            padding-left: 10px;
            text-transform: uppercase;

            span {
                padding: 4px 15px;
                background: url(img/icon.png) -37px -2px no-repeat;
                margin-right: 5px;
            }

        }

        .payment {
            width: 255px;
            float: right;
            margin-top: 15px;
            overflow: hidden;

            p.total {
                font-weight: bold;
                color: #FF0013;
                font-size: 15px;
                line-height: 25px;
                span {
                    display: block;
                    width: 70px;
                    float: left;
                }
            }



            p.payment2 {
                a {
                    background: #D14836;
                    color: #fff;
                    display: block;
                    width: 175px;
                    text-align: center;
                    line-height: 28px;
                    font-weight: bold;
                    margin-bottom: 5px;
                    border-radius: 3px;

                    &:hover {
                        background: #C43C2D;
                        text-decoration: none;
                    }
                }
            }
        }

        .bg_btn{
            width: 100%;
            text-align: right;
            padding: 20px 20px 0 0;
        }
        .bg_btn a{
            padding: 10px 15px;
            margin: 2px;
            color: #ffffff;
        }
        .bg_btn a:hover{
            text-decoration: none;
        }
        .bg_btn .btn_close{
            background-color: #0070BA;
        }
        .bg_btn .btn_next{
            background-color: #FF0013;
        }
        .bg_btn .btn_close:hover{
            background-color: #00619e;
        }
        .bg_btn .btn_next:hover{
            background-color: #ef000f;
        }

        .list_product {
            padding: 10px 10px 0;
            height: 437px;
            overflow: auto;
            background: #fff;
            border-bottom: 1px solid #ddd;
            line-height: 18px;

            .old {
                text-decoration: line-through;
                font-size: 12px;
                color: #999;
            }

            .discount {
                color:red;
                font-size: 12px;
                font-style: italic;
            }

            table {
                img {
                    margin: 0 5px 2px 0;
                }

                p.status {
                    color: green;
                    line-height: 30px;
                }
                a.delete{
                    color: red;
                    margin-left: 20px;
                }

                .promotion {
                    color: red;
                    font-style: italic;
                }
            }
        }

        table {
            width: 100%;
            border: 1px solid #ddd;

            th,
            td {
                border-bottom: 1px solid #ddd;
                padding: 6px;
                vertical-align: middle;
            }

            th {
                line-height: 20px;
                background: #eee;
                &.small{
                    font-weight: bold;
                }
            }

            .name {
                text-align: left;
            }

            .small {
                width: 1px;
                white-space: nowrap;
            }

            .icon-cut {
                padding: 2px 8px;
                background: url(img/icon.png) -156px -29px no-repeat;
                margin-left: 2px;
            }

            .thumb {
                float: left;
                margin-right: 2px;
                width: 50px;

                img {
                    width: 100%;
                    border-radius: 5px;
                }
            }

            .center {
                text-align: center;
            }
        }

        .empty_order {
            text-align: center;
            margin-top: 100px;
            font-size: 18px;
        }
    }


    #popup_banner{
        img{
            width: 100%;
        }

        &.window{
            width: 750px;

            @media screen and (max-width:800px) {
                width: 90%;
            }
        }
    }
}