.transfer_order {
	#content {
		.c {
			background-color: #ffffff;
			border: 1px solid $border-color-normal;
			padding: 10px;

			.form {

				@media screen and (max-width: 425px) {
					&.col-12 {
						padding-right: 0;
						padding-left: 0;
					}
					.transfer_date label {
					    width: 100%;
					}
				}

				&.transfer_form {
					@media screen and (min-width: 1200px) {
						.input {
							width: 84%;
						}

						label {
							width: 16%;
						}
					}

				}
				input.name {
					padding: 0 5px;
				}

				label {
					@media screen and (min-width: 1200px) {
						float: left;
						width: 27%;
					}
				}

				.input {

					@media screen and (min-width: 1200px) {
						float: left;
						width: 73%;
					}
				}

				.captcha {
					@media screen and (min-width: 1200px) {
						float: left;
						width: 100%;
					}

					label {
						margin-top: 20px;
						margin-right: 5px;

						@media screen and (min-width: 1200px) {
							float: left;
							width: 25%;
						}

						@media screen and (max-width: 500px) {
							margin-top: 0;
						}
					}

					#inner_captcha {
						float: left;

						@media screen and (max-width: 500px) {
							width: 100%;
							text-align: center;
						}
					}

					#img_captcha {
						@media screen and (min-width: 1200px) {
							float: left;
						}

						img {
							width: 200px;
							display: inline-block;
						}
					}

					.input {
						float: left;
						width: 29%;
						margin-top: 20px;

						@media screen and (min-width: 768px) {
							margin-right: 15px;
						}

						@media screen and (max-width: 560px) {
							width: 122px;
							margin-right: 8px;
						}

						@media screen and (min-width: 560px) and (max-width: 768px){
							width: 165px;
							margin-right: 11px;
						}
						@media screen and (max-width: 500px) {
							width: 182px;
							margin-top: 0;
						}

						@media screen and (max-width: 375px) {
							width: 100%;
						}

					}
				}
			}
		}
	}
}