/***** .alphabet *****/
ul {
    &.alphabet {
        position: relative;
        padding: 0;
        margin: 0 5px;
        width: 100%;
        float: left;

        li {
            background: #fff;
            float: left;
            margin: 0 -1px 5px 0;
            min-width: 24px;
            white-space: nowrap;
            width: 3.635%;
            list-style: none;

            a {
                background: #fff;
                border: 1px solid #ddd;
                border-radius: 2px;
                box-shadow: 1px 1px 0 #FFF inset;
                color: #555;
                font-weight: 700;
                line-height: 1.95em;
                position: relative;
                text-align: center;
                text-shadow: 1px 1px 0 rgba(255,255,255,.5);
                display: block;
                font-size: 11px;

                &:hover{
                    background: #ddd;
                    text-decoration: none;
                    box-shadow: none;
                }
            }

            &.current {
                a {
                    background: #ddd;
                    text-decoration: none;
                    box-shadow: none;
                }
            }
        }
    }
}