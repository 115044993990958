/**** .exp-to-mccash ****/
#content {
    .exp-to-mccash {
        .item {
            width: 25%;
            float: left;

            @media screen and (max-width: 480px) {
                 width: 50%;
            }

            &.disabled {
                .c {
                    background-color: #939393;
                    border: 2px solid #999;

                    &:hover {
                        background-color: darken(#939393, 10%);
                        border: 2px solid darken(#999, 12%);
                    }
                }
            }

            a {
                color: white;
                font-weight: bold;
                display: block;
                line-height: 18px;

                &:hover{
                    text-decoration: none;
                }
            }

            .c {
                margin: 10px;
                background-color: $button_1;
                border: 2px solid darken($button_1, 5%);
                border-radius: 5px;
                text-align: center;
                padding: 5px;

                &:hover{
                    background-color: darken($button_1, 10%);
                    border: 2px solid darken($button_1, 12%);
                }
            }
        }
    }
}

.exp_letter {
    letter-spacing: 0.5px;
}