/******* #nav-desktop ********/
#nav-desktop{
    font-family: 'OpenSans-SemiBold';
    position: absolute;
    bottom: 0;
    left: 0;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        float: left;
        width: 100%;

        li {
            float: left;
            position: relative;
            z-index: 1000;
            margin-right: 1px;


            //Chữ nhấp nháy
            @-webkit-keyframes my {
                0% { color: #ff0000; }
                50% { color: #fff;  }
                100% { color: #ff0000;  }
            }
            @-moz-keyframes my {
                0% { color: #ff0000;  }
                50% { color: #fff;  }
                100% { color: #ff0000;  }
            }
            @-o-keyframes my {
                0% { color: #ff0000; }
                50% { color: #fff; }
                100% { color: #ff0000;  }
            }
            @keyframes my {
                0% { color: #ff0000;  }
                50% { color: #fff;  }
                100% { color: #ff0000;  }
            }
            .nhap-nhay {
                -webkit-animation: my 700ms infinite;
                -moz-animation: my 700ms infinite;
                -o-animation: my 700ms infinite;
                animation: my 700ms infinite;
            }
            //Chứ nhấp nháy

            a {
                display: block;
                padding: 8px 15px;
                text-decoration: none;
                color: #fff;
                font-size: 14px;
            }

            &:hover {
                background: #F5F5F5;
                border-radius: 2px 2px 0 0;

                a {
                    color: $text-color-normal;
                }

                #instruction_3 {
                    display: none;
                }

                /* sub 1**/
                ul {
                    display: block;

                    ul {
                        display: none;
                    }
                }
            }

            &.active {
                background: #F5F5F5;
                border-radius: 2px 2px 0 0;

                a {
                    color: $text-color-normal;
                }
            }

            /** sub 1 **/
            ul {
                display: none;
                position: absolute;
                top: 28px;
                left: 0;
                width: auto;
                background-color: #FFFFFF;
                box-shadow: 0 6px 12px rgba(0,0,0,.175);

                a {
                    width: 320px;
                    text-transform: inherit;
                    font-size: 12px;
                }

                li {
                    &:hover {
                        ul {
                            display: block;
                        }
                    }
                    /* sub 2*/
                    ul {
                        display: none;
                        position: absolute;
                        top: 0;
                        left: 320px;
                    }

                }
            }

            &.shop {

                &:hover {
                    i{
                        background-position: -5px -210px;
                    }
                }

                i{
                    background: url(img/icon.png) -5px -176px no-repeat;
                    padding: 5px 12px;
                    margin-right: 5px;
                }

                a{
                    padding-left: 5px;
                }

                ul {
                    a{
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}

/******* #nav-right ********/
#nav-right{
    font-family: 'OpenSans-SemiBold';
    position: absolute;
    bottom: 0;
    right: 0;

    >ul {
		margin: 0;
        padding: 0;
        list-style: none;
        float: left;
        width: 100%;

        >li {
            float: left;
            position: relative;
            z-index: 1000;
            margin-right: 1px;

            >a {
                display: block;
                padding: 8px 15px;
                text-decoration: none;
                color: #fff;
                font-size: 13px;
            }

            &.show{
                background: #F5F5F5;
                border-radius: 2px 2px 0 0;

                >a {
                    color: $text-color-normal;
                }
                .instruction {
                    display: none;
                }
            }

            &.active {
                background: #F5F5F5;
                border-radius: 2px 2px 0 0;

                a {
                    color: $text-color-normal;
                }
            }

            /** sub 1 **/
            >ul {
				top: 100%!important;
				right: -1px!important;
				left: auto!important;
				transform: none!important;
				padding: 0;
				margin: 0;
				border: none;
                font-size: 1em;

                >li {
					width: 280px;
                }
            }
        }
    }

    .cart {
        a{
            background: url('img/icon.png') -160px -308px no-repeat;
            display: block;
            height: 25px;
            width: 25px;
            position: relative;

            &:hover {
                span{
                    width: 17px;
                    height: 17px;
                    top: 0;
                    right: 0;
                    font-size: 11px;
                }
            }

            span{
                position: absolute;
                top: 1px;
                right: 1px;
                background-color: #ff0013;
                border-radius: 12px;
                color: #fff;
                padding: 2px;
                font-size: 10px;
                display: block;
                width: 15px;
                height: 15px;
                text-align: center;
            }

        }
    }
}

.nav-dropdown{
    padding: 15px;
    margin-bottom: 0;
}

#popup_modal{
	.close_login{
	    margin-top: -15px;
		margin-right: -10px;
	}
	.nav-dropdown{
		padding: 0;
        border: none!important;
	}
}
.nav-mobile .logo {
    float: left;
    position: absolute;
    top: 6px;
    left: 43px;
    width: 96px;
    height: 23px;
}

.nav-mobile .logo:hover {
    text-decoration: none;
}

.nav-mobile .cart {
    right: 10px;
    top: 7px;
    width: 28px;
    background-position: -35px -5px;
}

.nav-mobile .cart:hover {
    background-position: -69px -5px;
}

.nav-mobile.fix-position {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    box-shadow: 0 0 3px #000;
}

.nav-mobile .r_account{
    position: absolute;
    right: 15px;
    top: 9px;
}
.nav-mobile .r_account .fa{
    font-size: 26px;
    color: #FFF;
}

.nav-mobile .mobile-cart{
    position: absolute;
    right: 50px;
    top: 9px;
    width: 25px;
    height: 25px;
    background: #ffffff url(img/icon.png) -251px -210px no-repeat;
    border-radius: 15px;
}
.nav-mobile .mobile-cart span{
    background: #ff0013;
    color: #ffffff;
    display: block;
    width: 16px;
    height: 16px;
    text-align: center;
    border-radius: 10px;
    font-size: 11px;
    font-weight: bold;
    line-height: 16px;
    position: absolute;
    right: 0;
    top: -3px;
    right: -3px;
}
.nav-mobile .mobile-cart a:hover span{
    text-decoration: none;
}
