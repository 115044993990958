/*** .detail-faq ***/
.detail-faq {
    #content {
        ul{
            &.list-question {
                margin-left: 30px;
            }
        }

        h2 {
            margin: 20px 0 8px;
            font-size: 20px !important;
        }

        h3 {
            font-size: 16px !important;
            background-color: #e9e9e9;
            padding: 5px;
            border-radius: 3px;

            &.highline {
                color: #ec3619;
            }
        }

        .item {
            margin-bottom: 20px;

            p, div {
                text-indent: 25px;
            }
        }

    }
}