.flashMessage {
    width: 100%;
    position: fixed;
    top: 10%;
    z-index: 9999;
    text-align: center;
}
.alert {
    padding: 10px;
    border-radius: 3px;
    display: inline-block;
    margin-bottom: 5px;
    z-index: 2;
    box-shadow: 0 0 10px #fff;
    border: 1px solid #fff;

    a.close {
        float: right;
        padding: 0 12px;
    }

    &.alert-success {
        background-color: #E4FFC6
    }

    &.alert-warning {
        background-color: #FFF0C6
    }

    &.alert-danger {
        background-color: #FFC7C6
    }
}

.msg-warning {
    color: red;
    padding: 4px;
    background-color: #f5ee08;
}