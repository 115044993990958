/***** .list-product *****/
#content {
    .list-product {
        .thumb {
            // max-width: 138px!important;

            div {
                line-height: 1px;
                width: 116px;
                height: 154px;
                overflow: hidden;
                margin-bottom: 4px;
                border-radius: 21px;
                box-shadow: 0 0 2px #dddddd;
                border: 1px solid #dddddd;

                img{
                    width: 119px;
                    max-width: 119px;
                    margin: -2px 0 0 -2px;
                }
            }
        }

        .product-col-right {
            .item {
                &:last-of-type {
                    .line {
                        .border-bottom {
                            border-bottom: none!important;
                        }
                    }
                }

                .price {
                    /*table {
                        width: 100%;
                        text-align: left;
                    }*/
                }
            }
        }
    }
}

.cart-btn{
    background-color: $button_3;
    border-radius: 20px;
    color: white;
    border: 1px solid $button_3;

    &:hover, &:focus {
        background-color: white;
        text-decoration: none;
        color: $button_3;
    }

    &.btn-preorder{
        padding: 5px 0;
        text-align: center;
    }

    &.btn-unstock {
        background-color: $button_4;
        border: 1px solid $border-color-normal;
        color: $text-color-normal;
        padding: 5px 0;
        text-align: center;

        /*@media screen and (max-width: 425px) {
            float: right;
        }*/

        &:hover, &:focus{
            cursor: pointer;
            color: $text-color-normal;
        }
    }

}

#content .list-product .product-col-right .item .price table th,
.detail-product #content table.price th{
    font-weight: normal;
    width: 75px;
}
#content .list-product .product-col-right .item .price table td,
.detail-product #content table.price td{
    font-weight: bold;
    font-size: 12px;
    margin-top: 1px;
    display: inline-block;
}
#content .list-product .product-col-right .item .price table td.price,
.detail-product #content table.price td.price{
    color: #ed1c50;
}
#content .list-product .product-col-right .item .price .old{
    text-decoration: line-through;
    color: #888;
    font-weight: normal;
}


/**** .box-product ****/
#content {
    .box-product {
        margin-top: 15px;

        .box {
            width: 20%;
            float: left;

            .bg-box {
                margin: 3px;
                background: #fff;
                border: 1px solid #ddd;
                padding-bottom: 2px;

                &:hover {
                    border: 1px solid #aaa;
                }
            }
        }

        .thumb {
            img {
                height: auto;

                // @media screen and (max-width: 768px) {
                //     width: 90px!important;
                //     height: 126px!important;
                // }
            }
        }
    }
}

#content .title,
#content .title a {
    font-size: 17px !important;
    color: $text-color-normal;
    margin-bottom: 5px;
}

#content .box-product .thumb {
    position: relative;
    padding: 1px 0 1px 1px;
}
#wrapper #content div.title{
    font-weight: bold;
    border: none;
    border-bottom: 1px solid #dddddd;
    background: #ffffff;
    padding: 10px 0 5px 0;
}


#content .box-product .thumb div,
.sidebar .product .thumb div {
    display: block;
    position: absolute;
    background-image: url(img/icon.png);
    background-repeat: no-repeat;
}

#content .box-product .thumb .discount,
.sidebar .product .thumb .discount {
    width: 44px;
    height: 29px;
    background-position: -2px -68px;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    padding-top: 5px;
    top: 0;
    right: 0;
}

#content .box-product .thumb .promotion,
.sidebar .product .thumb .promotion {
    background-position: -2px -102px;
    bottom: 5px;
    right: 8px;
    height: 31px;
    width: 26px;
}

#content .box-product .thumb a {
    display: block;
    line-height: 1px;
    width: 105px;
    height: 141px;
    overflow: hidden;
    margin-bottom: 4px;
    border-radius: 21px;
    box-shadow: 0 0 2px #dddddd;
    border: 1px solid #dddddd;
    margin: 0 auto;

    img{
        margin: -2px 0 0 -2px;
        max-width: 107px;
        width: 107px;
    }
}

#content .box-product .box a:hover {
    text-decoration: none;
}

#content .box-product .box .info,
.sidebar .product .info {
    padding: 2px 3px;
    text-align: center;
}

#content .box-product .box .info .name,
.sidebar .product .info .name {
    height: 40px;
    overflow: hidden;
    line-height: 18px;
}

#content .box-product .box .info .price,
.sidebar .product .info .price {
    height: 20px;
    overflow: hidden;
}

#content .box-product .box .info .old,
.sidebar .product .info .old {
    color: #999;
    text-decoration: line-through;
    font-size: 12px;
}

#content .box-product .box .info .new,
.sidebar .product .info .new {
    color: #EC3619;
    font-size: 15px;
    font-weight: bold;
}


/****** .box-product-category ******/
#content {
    .box-product-category {
        background-color: #fff;
        border: 1px solid $border-color-normal;
        margin-bottom: 12px;
        padding: 10px;

        .title {
            background-color: $background_title;
            padding: 10px;
            border-bottom: 1px solid $border-color-normal;
            font-size: 24px !important;
            margin-bottom: 15px;

            .more{
                float: right;
                font-size: 13px !important;
                color: $text-link;
                text-transform: none;
                font-weight: normal;
                margin-right: 3px;
            }
        }

        .box{
            width: 20%;
            text-align: center;
            float: left;
            margin-bottom: 25px;

            .thumb{
                padding: 0 11px;
            }

            .name{
                height: 20px;
                overflow: hidden;
                margin-bottom: 6px;
            }
        }
    }

    .box-post{
        background-color: #fff;
        border: 1px solid $border-color-normal;
        margin-bottom: 12px;
        padding: 10px;

        .title{
            background-color: $background_title;
            padding: 10px;
            font-size: 24px !important;
            margin-bottom: 15px;
        }
    }
}

/***** .detail-product *****/
.detail-product {
    .read {
        .top {

            .column {

                &.left{

                    @media screen and (min-width: 660px) {
                        /*width: 65%;*/
                    }

                    @media screen and (max-width: 440px) {
                        text-align: center;
                        /*width: 100%;*/
                    }

                    .thumb{
                        // max-width: 138px!important;

                        div {
                            line-height: 1px;
                            width: 116px;
                            height: 154px;
                            overflow: hidden;
                            margin-bottom: 6px;
                            border-radius: 21px;
                            box-shadow: 0 0 2px #dddddd;
                            border: 1px solid #dddddd;
                        }

                        img {
                            width: 118px;
                            max-width: 118px;
                            margin: -2px 0 0 -2px;
                        }
                    }
                }

                &.info{

                    .cart-button {
                        /*margin-top: 10px;*/

                        .cart-btn {
                            /*text-align: center;*/

                            @media screen and (max-width: 576px) {
                                float: right;
                            }
                        }
                    }

                    .price {
                        /*width: 100%;*/
                        text-align: left;

                        .member {
                            p{
                                line-height: 15px;
                                margin-bottom: 0;
                            }
                        }
                    }

                    .cart-button {
                        @media screen and (max-width: 460px) {
                            /*text-align: center;*/

                            /*.btn {
                                display: inline-block;
                            }*/
                        }
                    }
                }

                .title2{
                    @media screen and (max-width: 440px) {
                        width: 100%;
                        text-align: left;
                        margin: 15px 0 0!important;
                    }

                    @media screen and (min-width: 920px) {
                        width: 73%
                    }

                    @media screen and (min-width: 660px) and (max-width: 920px) {
                        width: 65%;
                    }

                    h1{
                        font-size: 14px;
                        color: $text-link;
                        font-weight: normal;
                        @media screen and (max-width: 440px) {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .description {
            .title3{
                border-bottom: 1px solid $border-color-normal;
                line-height: 30px;
                color: $text-color-normal;
                margin-top: 20px;
                font-size: 15px;
                font-weight: normal;
            }
        }

        .old{
            text-decoration: line-through;
            color: #999;
        }
    }
}

.picon {
    .hot {
        margin-right: 3px;
        height: 20px;

        img {
            height: 20px;
            width: 45px;
        }

        span {
            top: 0;
            left: 7px;
            color: #fff;
            font-size: 12px;
            font-weight: bold;
        }
    }
    .sale {
        height: 20px;

        img {
            height: 20px;
            width: 45px;
        }
        span {
            top: 0px;
            left: 4px;
            color: #fff;
            font-size: 12px;
            font-weight: bold;
        }
    }

    del {
        color: #9d68a3;
    }
}