/*** .star_rate ***/
.bg_star_rate {
    .star_rate {
        float: left;
        margin: 0 !important;

        p{
            margin: 0 !important;
        }
    }

    p {
        &.c {
            line-height: 20px;
            font-style: italic;
        }
    }
}


.star_rate {
    width: 100px;
    overflow: hidden;
    position: relative;
    background-position: left top;
    margin-bottom: 5px;
    background-image: url(img/star.gif);
    height: 20px;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}

.star_rate_2 {
    background-image: url(img/star.gif);
    height: 20px;
    background-position: 0 -20px;
    position: absolute;
}

.star_rate_3 {
    position: absolute;

    a {
        position: absolute;
        top: -1px;
        text-decoration: none;
        height: 20px;
        z-index: 10;

        &:hover {
            display: block;
            background-image: url(img/star.gif);
            background-position: 0 -39px;
        }

        &.star1 {
            width: 20px;
            z-index: 8;
        }

        &.star2 {
            width: 40px;
            z-index: 6;
        }

        &.star3 {
            width: 60px;
            z-index: 4;
        }

        &.star4 {
            width: 80px;
            z-index: 2;
        }

        &.star5 {
            width: 100px;
            z-index: 1;
        }
    }
}