/******* .tag ********/
#content {
	.tag {
    margin: 10px 0;

    span {
      font-weight: bold;

   //    &.icon-tag {
		 //    background-position: -90px -50px;
		 //    padding: 1px 9px;
			// }
    }

    a {
    	border: 1px solid #6cb2e4;
	    border-left: 0;
	    padding: 0 5px 1px 2px;
	    border-radius: 3px;
	    background-color: #f0f7fc;
	    display: inline-block;
	    position: relative;
	    margin-left: 15px;

			&::before {
				content: "";
				float: left;
				border-top: 11px solid transparent;
				border-bottom: 12px solid transparent;
				border-left: 14px solid transparent;
				border-right: 10px solid #6cb2e4;
				position: absolute;
				top: 0;
				left: -23px;
			}

    	&::after {
		    content: "";
				float: left;
				border-top: 11px solid transparent;
				border-bottom: 12px solid transparent;
				border-left: 12px solid transparent;
				border-right: 10px solid #f0f7fc;
				position: absolute;
				top: 0;
				left: -20px;
    	}

    	span {
    		width: 7px;
    		height: 7px;
    		display: inline-block;
    		border-radius: 20px;
    		border: 1px solid;
    		margin-right: 8px;
    	}
    }
	}
}
