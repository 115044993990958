$primary-color: #0070BA;
$brand-color: #ff9900;
$text-link: #0882e8;
$button: #ff9900;
$button_1: #4eb14e;
$button_2: #0070BA;
$button_3: #48cd89;
$button_4: #f8f8f8;
$btn_google_plus: #4285f4;
$btn_facebook: #4267b2;
$text-error: #ff0000;
$text-white: #fff;
$text-red: #FF0013;
$text-color-normal: #555555;
$border-color-normal: #dddddd;
$background: #fff;
$background_footer: #fff;
$background_title: #ffffff;