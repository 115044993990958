/***** .comment *****/
#content {
    .comment-web{
        border: 1px solid #eee;

        .title {
            font-weight: bold;
            margin-bottom: 6px;
            display: block;
            background: none;
            border: none;
            padding: 0;
        }

        .larger {
            background-color: #fff;
        }

    }
}


/***** #view-comment *****/
#view-comment {
    padding: 10px 0 0;

    .loading {
        float: right;
    }

    .item {
        margin-bottom: 11px;
        padding: 12px 0;
        border-bottom: 1px solid #e0e0e0;
        text-align: justify;
        overflow: hidden;

        .datetime {
            font-size: 0.8em;
            color: #999;
            font-weight: normal;
            padding-left: 10px;
        }

        .answer {
            margin: 10px 0 20px;
            border-top: 1px solid #e0e0e0;
            padding: 10px 0;

            .thumb {
                width: 45px;
                padding: 0;
                border: none;

                img {
                    width: 40px;
                }
            }

            .info {
                border-left: none;
                margin-left: 45px;
            }
        }

        .name {
            font-weight: bold;
            margin-bottom: 5px;
            overflow: hidden;
        }

        .thumb {
            width: 130px;
            float: left;
            text-align: center;
            border-right: 1px solid #e0e0e0;

            img {
                border-radius: 3px;
                width: 80px;
            }

            .time {
                color: #999;
                font-size: 12px;
            }
        }

        .info {
            margin-left: 129px;
            border-left: 1px solid #e0e0e0;
            padding-left: 20px;

            .name {
                span{
                    float: left;
                }

                .bg_star_rate{
                    float: left;
                }
            }
        }
    }

    .button-answer {
        overflow: hidden;
        margin-top: 10px;

        .like {
            float: left;
            padding-top: 5px;
            color: #777;
            font-style: italic;
            font-size: 13px;

            .number-like{
                color: #2B7EF2;
                margin-right: 10px;
            }

            a {
                line-height: 25px;
                margin-left: 10px;
                font-style: normal;

                &:hover{
                    text-decoration: none;
                    font-weight: bold;
                }
            }

            span {

                &.icon {
                    background: url(img/icon.png) -188px -27px no-repeat;
                    padding: 3px 10px 1px;
                }
            }
        }

        .btn {
            height: 30px;
            line-height: 30px;
            float: right;
            width: auto;
            padding: 0 10px;
        }
    }

    .form-answer {
        display: none;

        .button-answer {
            text-align: center;
            margin-bottom: 30px;

            .btn {
                float: none;
                margin: 0 auto;
            }
        }
    }
}


/***** .comment-web-rate *****/
.comment-web-rate {
    margin-top: 15px;

    .title {
        font-weight: bold;
    }

    .c {
        padding: 20px 10px;
        overflow: hidden;
        margin-bottom: 20px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
    }

    .box1, .box2, .box3 {
        float: left;
        border-right: 1px solid #eee;
    }

    .box1 {
        width: 25%;
        text-align: center;
        padding-right: 20px;

        .rate {
            font-weight: bold;
            font-size: 35px;
            margin: 20px 0;
            color: #f63;
        }

        .bg_star_rate{
            overflow: hidden;
            margin: 8px auto;
            width: 100px;
        }
    }
    .box2 {
        width: 38%;
        padding: 0 40px 0 30px;

        .item {
            float: left;
            width: 100%;
            margin-bottom: 10px;
            line-height: 16px;
        }
    }

    .box3 {
        width: 32%;
        border-right: none;
        padding: 30px 0 0 13px;
    }

    .t {
        font-weight: bold;
        color: #555;
        text-align: center;
    }

    .rating-num, .progress {
        float: left;
    }

    .rating-num {
        margin-right: -50px;
    }

    .rating-num-total {
        margin-left: -20px;
        float: right;
    }

    .progress {
        width: 70%;
        height: 18px;
        background-color: #f5f5f5;
        border-radius: 4px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
        margin: 0 20px 0 50px;
        overflow: hidden;
    }

    .success{
        height: 100%;
        background-color: #5cb85c;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
        transition: width 0.6s ease 0s;
    }

    .btn{
        text-transform: none;
        width: 200px !important;
        font-size: 14px;
        margin-top: 10px;
    }
}