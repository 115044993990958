/******* #footer *******/
#bg-footer{
    /*background-color: $background_footer;*/
    /*border-top: 1px solid darken($background_footer, 15%);*/
    /*padding-top: 20px;*/
}
#footer {
    .c{
        padding: 10px 5px;
    }

    @media screen and (max-width: 900px) {
        .container{

        }
    }

    .col-6{
        margin-bottom: 20px;
    }

    .copy-right {
        line-height: 20px;
    }

    .title{
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 15px;
        font-size: 13px !important;
    }

    .title-sub{
        margin: 15px 0 6px;
        font-weight: bold;
    }

    ul, ol{
        margin-bottom: 10px;
    }
    ul li, ol li{
        list-style: none;
        padding: 5px 0;
        line-height: 18px;
    }

    .logo{
        ul{
            margin-bottom: 0;
        }
        ul li{
            padding: 0;
        }
    }

    @media screen and (max-width: 575px) {
        .bg-box.row{
            margin: 0;
        }
        .copy-right{
            padding: 10px 10px 0;
            border-top: 1px solid #dddddd;
        }
    }

    .c {
        /*color: #444;*/
        /*padding-bottom: 30px;*/
    }

    .bg-box {
        /*overflow: hidden;*/
        /*margin-bottom: 20px;*/
    }

    .box {
        /*float: left;*/

        &.method-payment{
            /*margin-left: 205px;*/
            /*margin-right: 170px;*/

            img{
                /*border: 1px solid #ddd;
                border-radius: 3px;*/

                @media all and (max-width: 768px) {
                }
            }
        }

        &.about {
            /*width: 200px;*/
            /*margin-right: -200px;*/

            ul {
                li {
                    list-style: none;

                    a {
                        display: block;
                        font-size: 13px;
                        line-height: 30px;
                    }
                }
            }
        }

        &.certify{
            /*width: 137px;*/
            /*float: right;*/
            /*margin-left: -150px;*/

            ul{
                /*list-style: none;*/

                li{
                    /*margin-bottom: 5px;*/
                    /*text-align: center;*/

                    img{
                        /*border: 1px solid #ddd;*/
                        /*border-radius: 3px;*/

                       /* @media all and (max-width: 768px) {
                            max-width: 150px!important;
                            max-height: 50px!important;
                        }*/
                    }
                }
            }
        }

        .title{
            /*display: block;*/
            /*font-weight: bold;*/
            /*text-transform: uppercase;*/
            font-size: 12px;
            /*margin-bottom: 25px;*/
            /*line-height: 22px;*/
        }

        .social {
            .title{
                /*margin: 15px 0 5px;*/
            }
            ul {
                /*display: inline-block;
                text-align: center;
                width: 100%;*/

                li{
                    /*display: inherit;*/
                    /*margin-right: 3px;*/

                    img{
                        /*border: none;*/
                    }
                }
            }
        }
    }
}

/**** .nav ****/
/*.nav {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
        background: #727272;
        overflow: hidden;

        li {
           display: inline;

           a {
                padding: 5px 5px;
                line-height: 28px;
                color: #fff;

                &:hover {
                    text-decoration: none;
                    background-color: #5B5B5B;
                }
            }
        }
    }
}
*/