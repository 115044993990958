/***** .choose-method ****/
#content .choose-method{
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid $border-color-normal;

    .box{
        width: 50%;
        float: left;
        background-color: #fff;
        text-align: justify;

        @media screen and (max-width: 600px) {
            width: 100%;
        }

        &.l{
            border-right: none;
            border-right: 1px solid $border-color-normal;
            border-top-right-radius: 0;

            .title{
                border-top-right-radius: 0;
            }
        }

        .c{
            padding: 10px;
        }

        .title{
            text-align: center;
            height: 30px;
            line-height: 30px;
            font-weight: bold;
            font-size: 13px !important;
            text-transform: uppercase;
            border: none;
            border-bottom: 1px solid $border-color-normal;
        }

        &.r {
            border-top-left-radius: 0;

            .title{
                border-top-left-radius: 0;
            }
        }
    }

    .btn{
        margin: 20px 0 10px;
    }
}

.choose-method ul{
    list-style-position: inside;
    margin: 5px 0 10px 20px;
}

/***** .select-method ******/
#content .select-method{
    /*margin-top: 20px!important;*/

    .item {
        margin: 4px 0;
    }
}
#content .select-method {

    .item {
        &:hover {
            .title{
                background-color: $primary-color;
                border-color: $primary-color;
                border-radius: 2px 2px 0 0;

                a, span {
                    color: #FFFFFF;
                }

                .icon-arrow {
                    filter: invert(100%);
                }
            }
        }

        .detail {
            .amount {
                input {

                    @media screen and (max-width: 425px) {
                        width: 100%;
                    }
                }
            }
        }
    }

    label {
        margin: 0;
        /*height: auto;*/
    }

    .input {
    }

    .active {
        .title{
            background-color: $primary-color;
            border-color: $primary-color;
            border-radius: 2px 2px 0 0;
        }
    }

    .title{
        background-color: $background_title;
        border: 1px solid $border-color-normal;
        border-radius: 2px;

        a {
            display: block;
            color: #333;
            font-size: 12px !important;
            font-weight: bold;
            line-height: 27px;
            margin: 0;

            &:hover{
                text-decoration: none;
            }
        }

        span{
            font-weight: normal;
            color: #ff0000;
            font-size: 11px;
        }
    }
}

#content .select-method .active .title a,
#content .select-method .active .title span{
    color: #FFFFFF;
}
#content .select-method .active{
    /*box-shadow: 1px 1px 2px #eee;*/
}
#content .select-method .item .c{
    display: none;
    border: 1px solid $border-color-normal;
    margin: 0;
    padding: 10px;
}
#content .select-method .icon-arrow{
    background: url(img/icon.png) -94px -314px no-repeat;
    padding: 1px 7px;
}
#content .select-method .active .icon-arrow{
    background-position: -123px -314px;
}
#content .select-method .active .des{
    padding-bottom: 15px;
    margin-bottom: 15px;
    border: none;
}
#content .select-method table.amount{
    // width: 70%;
    width: 315px;
    margin: 25px auto 0;

    @media screen  and (max-width: 600px){
        // width: 61%;
    }

    @media screen  and (max-width: 425px){
        width: 95%;

        .input {
            width: 100%;
        }
    }


}
#content .select-method table.amount div,
#content .select-method table.amount input{
    margin: 0;

    @media screen  and (max-width: 460px){
        width: 100%;
    }
}

#content .select-method table.amount .noti-mccash{
    color: red;
    font-size: 0.9em;
    font-style: italic;
}

#content .select-method {
    table.amount {
        tr{
            td {
                padding-bottom: 10px;

                @media screen  and (max-width: 600px){
                    float: left;
                    width: 100%;
                }
            }

            th{
                padding-bottom: 10px;

                @media screen  and (max-width: 600px){
                    float: left;
                    width: 100%;
                }
            }

            // &:last-child {
                td {
                    @media screen  and (max-width: 600px){
                        width: 47%;
                    }
                }

                th{
                    @media screen  and (max-width: 600px){
                        width: 53%;
                    }
                }
            // }
        }
    }
}
#content .select-method table.amount th,
#content .select-method table.amount label {
    color: #333;
}
#content .select-method table.list-payment-method {
    width: 100%;
    margin-bottom: 10px;
}
#content {
    .select-method {
        table.list-payment-method {
            input[type="radio"]:checked+label.thumb {
                border: 1px solid $primary-color!important;
                // filter: grayscale(0);
                // -o-filter: grayscale(0);
                // -ms-filter: grayscale(0);
                // -moz-filter: grayscale(0);
                // -webkit-filter: grayscale(0);
            }

            .col1 {
                width: 1px;
                padding: 0;
                display: none;

                @media screen  and (max-width: 600px) {
                    border-bottom: none;
                }
            }

            .col2 {
                width: 120px;

                @media screen  and (max-width: 600px) {
                    border-bottom: none;
                }

                input {
                    display: none;
                }
            }

            .col3 {
                padding-left: 98px;

                @media screen  and (max-width: 480px) {
                    padding-left: 10px;
                    text-align: left;
                }

                @media screen  and (max-width: 320px) {
                    width: 100%;
                    padding-left: 68px;
                }

            }
        }
    }
}


#content {
    .select-method {
        table{
            &.list-payment-method {

                tr {
                    &:hover {
                        img{
                            border: 1px solid $primary-color;
                        }

                        .thumb{
                            border-color: $primary-color;
                        }
                    }
                }

                td{
                    vertical-align: middle;
                    padding: 5px 0 10px 20px;
                    border-bottom: 1px solid #eee;
                }

                label{
                    font-weight: normal;
                    color: #333;

                    &:hover{
                        cursor: pointer;
                    }
                }

                input{
                    margin: 0;
                    box-shadow: none;
                }

                div{
                    margin: 0;
                }

                img{
                 /*   border: 2px solid #ccc;
                    border-radius: 3px;*/
                }

                .thumb{
                    background-image: url(img/bank_logo-2.jpg);
                    background-repeat: no-repeat;
                    width: 100px;
                    height: 52px;
                    background-color: white;
                    border: 1px solid $border-color-normal;
                    // filter: grayscale(100%);
                    // -o-filter: grayscale(100%);
                    // -ms-filter: grayscale(100%);
                    // -moz-filter: grayscale(100%);
                    // -webkit-filter: grayscale(100%);
                }


                .vietcombank{
                    background-position: 0 -252px
                }
                .donga{
                    background-position: 0 -505px
                }
                .achau{
                    background-position: 0 -693px
                }
                .vietinbank{
                    background-position: 0 -196px
                }
                .sacombank{
                    background-position: 0 -378px
                }
                .techcombank{
                    background-position: 0 -317px
                }
                .bidv{
                    background-position: 0 -567px
                }
                .mb{
                    background-position: 0 -440px
                }
                .agribank{
                    background-position: 0 -128px
                }
                .virtual_visa{
                    // background-position: 0 -64px
                    background-position: 0 -1179px
                }
                .western_union{
                    background-position: 0 0
                }
                .baokim{
                    background-position: 0 -630px
                }
                .nganluong{
                    background-position: 0 -820px
                }
                .wmtransfer{
                    background-position: 0 -880px
                }
                .vtc_pay{
                    background-position: 0 -762px
                }
                .credit{
                    background-position: 0 -1179px
                }
                .zalo_pay{
                    background-position: 0 -1248px
                }
                .momo{
                    background-position: 0 -1389px
                }
                .viettel_pay{
                    background-position: 0 -1312px
                }
                .viettel_store{
                    background-position: 0 -1456px
                }
                .post_office{
                    background-position: 0 -1524px
                }
                .fpt_shop{
                    background-position: 0 -1636px
                }
                .thegioididong{
                    background-position: 0 -1588px
                }

            }
        }
    }
}

#content .select-method {
    .detail {
        .notification {
            margin-bottom: 15px;

            span{
                color: red;
                text-decoration: underline;
                font-style: italic;
                font-weight: bold;
            }
        }
    }
}

/**** .list-payment-method2 ****/
#content .list-payment-method2 {
    overflow: hidden;

    ul{
        list-style: none;
        width: 100%;
        margin: 0;

        @media screen and (max-width: 768px){
            display: inline-block;
            text-align: center;
        }

        li{
            text-align: center;
            /*float: left;*/
            display: inline-block;
            padding: 0 15px 10px;

            @media screen and (max-width: 768px){
                float: none;
                display: inline-block;
                padding: 0 16px 15px;
                list-style: none;
            }

            .MASTE {
                background-position:5px -31p
            }
            .AMREX {
                background-position:5px -62px
            }
            .JCB {
                background-position:5px -93px;
            }
            .VCB {
                background-position:6px -123px;
            }
            .TCB {
                background-position:5px -155px;
            }
            .MB {
                background-position:5px -186px;
            }
            .VIB {
                background-position:5px -217px;
            }
            .ICB {
                background-position:5px -248px;
            }
            .EXB {
                background-position:5px -279px;
            }
            .ACB {
                background-position:5px -309px;
            }
            .HDB {
                background-position:5px -341px;
            }
            .MSB {
                background-position:5px -372px;
            }
            .NCB {
                background-position:5px -400px;
            }
            .DAB {
                background-position:5px -434px;
            }
            .SHB {
                background-position:5px -465px;
            }
            .OJB {
                background-position:5px -496px;
            }
            .SEA {
                background-position:5px -527px;
            }
            .TPB {
                background-position:5px -558px;
            }
            .PGB {
                background-position:5px -589px;
            }
            .BIDV {
                background-position:6px -619px;
            }
            .AGB {
                background-position:5px -651px;
            }
            .SCB {
                background-position:5px -679px;
            }
            .VPB {
                background-position:5px -709px;
            }
            .VAB {
                background-position:5px -740px;
            }
            .GPB {
                background-position:5px -774px;
            }
            .SGB {
                background-position:5px -806px;
            }
            .NAB {
                background-position:5px -1437px;
            }//837px
            .BAB {
                background-position:5px -868px;
            }

            .BVB{
                background-position:5px -1051px;
            }
            .ABBank {
                background-position:5px -958px;
            }
            .SCBBank {
                background-position:5px -1387px;
            }
            .PVCombank {
                background-position:5px -1086px;
            }
            .LienvietBank{
                background-position:5px -1339px;
            }
            .PublicBank {
                background-position:5px -1185px;
            }
            .VRB {
                background-position:5px -1286px;
            }
            .KienLongBank {
                background-position:5px -1240px;
            }

            label{
                background-image: url(img/bank_logo.png);
                background-repeat: no-repeat;
                width: 94px;
                height: 35px;
                border: 1px solid $border-color-normal;
                // filter: grayscale(100%);
                // -o-filter: grayscale(100%);
                // -ms-filter: grayscale(100%);
                // -moz-filter: grayscale(100%);
                // -webkit-filter: grayscale(100%);

                &:hover {
                    cursor: pointer;
                    border:1px solid $primary-color;
                }
            }

            input {
                box-shadow: none;
                width: 0;
                height: 0;
                position: absolute;
                z-index: -1;
            }

            input[type="radio"]:checked+label {
                border:1px solid $primary-color!important;
                // filter: grayscale(0);
                // -o-filter: grayscale(0);
                // -ms-filter: grayscale(0);
                // -moz-filter: grayscale(0);
                // -webkit-filter: grayscale(0);
            }
        }
    }
}

/** .by_card **/
#content .select-method {
    .by_card{
        width: 90%;
        margin: 0 auto;

        @media screen and (max-width: 375px) {
            width: 95%;
        }

        label {
            margin: 0;
            height: auto;
            color: inherit;

            @media screen and (min-width: 425px) {
                width: 30%;
                float: left;
            }

            span {
                color: red;
            }
        }

        .input {
            @media screen and (min-width: 425px) {
                width: 70%;
                float: left;
            }
        }
    }
}


/*** .pay-webmoney ****/
#content {
    .pay-webmoney {
        table{
            width: 50%;
            margin: 0 auto;
            line-height: 30px;

            th{
                font-weight: normal;
                width: 180px;
            }

            td{
                font-weight: bold;
            }

        }
    }
}


/***** .order-information *****/
.order-information {
    h1{
        font-weight: bold;
    }
    .col {
        width: 50%;
        float: left;
        padding: 0 20px 0 0;
        margin-bottom: 20px;


        &.col-left {
            table {
                border: 1px solid #ddd;
            }

            input,select{
                margin-bottom: 10px;
            }
        }

        .delete{
            color: red !important;
        }

        .coupon {
            .input {
                float: left;
            }

            a {
                line-height: 28px;
                margin-left: 10px;
            }
        }

        .success{
            color: green;
        }
        .warning{
            color: #ff8500;
        }


        table {
            width: 100%;

            td,
            th {
                border: 1px solid #777;
                text-align: center;
                padding: 3px 2px;
                color: #333;
            }

            th {
                background: #999;
                color: #fff;

                &.stt {
                    width: 45px;
                }

                &.price {
                    width: 90px;
                }

                &.qty {
                    width: 30px;
                }
            }

            td {
                &.name {
                    text-align: left;
                }
            }


            tr.total {
                th,
                td {
                    color: #ec3619;
                    font-weight: bold;
                    border: none;
                    padding: 8px 0 0;
                }

                th {
                    background: transparent;
                    text-align: right;
                }
            }

            a{
                color: #333333;
            }

            .status {
                color: green;
                font-size: 0.9em;
            }

        }

        .price {

            .old {
                color: #aaa;
                text-decoration: line-through;
                font-size: 0.9em;
            }

            .discount {
                color: #ec3619
            }
        }
    }

    .col-right {
        padding: 30px 0 0 0;
    }
}

/**** .confirm ****/
.confirm.order-information .col-right {
    padding-top: 0;
}

.confirm.order-information table.customer th {
    background-color: #fff;
    color: #333;
    text-align: right;
    padding-right: 30px;
    width: 150px;
}

.confirm.order-information table.customer td {
    text-align: left;
}

.confirm.order-information table.customer th,
.confirm.order-information table.customer td {
    border: none;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

.confirm.order-information table.customer tr:nth-child(odd) th,
.confirm.order-information table.customer tr:nth-child(odd) td {
    background: #eee;
}


/*2.1*/
input[type="radio"]:checked+label.thumb {
    border: 1px solid $primary-color!important;
    // filter: grayscale(0);
    // -o-filter: grayscale(0);
    // -ms-filter: grayscale(0);
    // -moz-filter: grayscale(0);
    // -webkit-filter: grayscale(0);
}

.select-method {
    ul.list-unstyled {

        li{
            display: inline-block;
            padding: 0 1em 10px;
        }
    }

    &.list-payment-method {

        label {
            &:hover {
                cursor: pointer;
                border:1px solid $primary-color;
            }
        }

        .select-account {
            &:hover {
                img{
                    border: 1px solid $primary-color;
                }

                .thumb{
                    border-color: $primary-color;
                }
            }

            >div{
                vertical-align: middle;
                padding: 5px 0 10px 20px;
                border-bottom: 1px solid #eee;
            }
        }



        label{
            font-weight: normal;
            color: #333;

            &:hover{
                cursor: pointer;
            }
        }

        input{
            display: none;
        }

        img{
            /*border: 2px solid #ccc;
            border-radius: 3px;*/
        }

        .thumb{
            background-image: url(img/bank_logo-2.jpg);
            background-repeat: no-repeat;
            width: 100px;
            height: 53px;
            background-color: white;
            border: 1px solid $border-color-normal;
            // filter: grayscale(100%);
            // -o-filter: grayscale(100%);
            // -ms-filter: grayscale(100%);
            // -moz-filter: grayscale(100%);
            // -webkit-filter: grayscale(100%);
        }


        .vietcombank{
            background-position: 0 -252px
        }
        .donga{
            background-position: 0 -505px
        }
        .achau{
            background-position: 0 -693px
        }
        .vietinbank{
            background-position: 0 -196px
        }
        .sacombank{
            background-position: 0 -378px
        }
        .techcombank{
            background-position: 0 -317px
        }
        .bidv{
            background-position: 0 -567px
        }
        .mb{
            background-position: 0 -440px
        }
        .agribank{
            background-position: 0 -128px
        }
        .virtual_visa{
            // background-position: 0 -64px
            background-position: 0 -1179px
        }
        .western_union{
            background-position: 0 0
        }
        .baokim{
            background-position: 0 -630px
        }
        .nganluong{
            background-position: 0 -820px
        }
        .wmtransfer{
            background-position: 0 -880px
        }
        .vtc_pay{
            background-position: 0 -762px
        }
        .credit{
            background-position: 0 -1179px
        }
        .zalo_pay{
            background-position: 0 -1248px
        }
        .momo{
            background-position: 0 -1389px
        }
        .viettel_pay{
            background-position: 0 -1312px
        }
        .viettel_store{
            background-position: 0 -1456px
        }
        .post_office{
            background-position: 0 -1524px
        }
        .fpt_shop{
            background-position: 0 -1636px
        }
        .thegioididong{
            background-position: 0 -1588px
        }

    }
}


/**** .list-payment-method2 ****/
#content .list-payment-method2 {
    overflow: hidden;

    ul{

        li{
            .MASTE {
                background-position:5px -31px
            }
            .AMREX {
                background-position:5px -62px
            }
            .JCB {
                background-position:5px -93px;
            }
            .VCB {
                background-position:6px -123px;
            }
            .TCB {
                background-position:5px -155px;
            }
            .MB {
                background-position:5px -186px;
            }
            .VIB {
                background-position:5px -217px;
            }
            .ICB {
                background-position:5px -248px;
            }
            .EXB {
                background-position:5px -279px;
            }
            .ACB {
                background-position:5px -309px;
            }
            .HDB {
                background-position:5px -341px;
            }
            .MSB {
                background-position:5px -372px;
            }
            .NCB {
                background-position:5px -400px;
            }
            .DAB {
                background-position:5px -434px;
            }
            .SHB {
                background-position:5px -465px;
            }
            .OJB {
                background-position:5px -496px;
            }
            .SEA {
                background-position:5px -527px;
            }
            .TPB {
                background-position:5px -558px;
            }
            .PGB {
                background-position:5px -589px;
            }
            .BIDV {
                background-position:6px -619px;
            }
            .AGB {
                background-position:5px -651px;
            }
            .SCB {
                background-position:5px -679px;
            }
            .VPB {
                background-position:5px -709px;
            }
            .VAB {
                background-position:5px -740px;
            }
            .GPB {
                background-position:5px -774px;
            }
            .SGB {
                background-position:5px -806px;
            }
            .NAB {
                background-position:5px -1437px;
            }//837px
            .BAB {
                background-position:5px -868px;
            }

            .BVB{
                background-position:5px -1051px;
            }
            .ABBank {
                background-position:5px -958px;
            }
            .SCBBank {
                background-position:5px -1387px;
            }
            .PVCombank {
                background-position:5px -1086px;
            }
            .LienvietBank{
                background-position:5px -1339px;
            }
            .PublicBank {
                background-position:5px -1185px;
            }
            .VRB {
                background-position:5px -1286px;
            }
            .KienLongBank {
                background-position:5px -1240px;
            }

            label{
                background-image: url(img/bank_logo.png);
                background-repeat: no-repeat;
                width: 94px;
                height: 35px;
                border: 1px solid $border-color-normal;
                // filter: grayscale(100%);
                // -o-filter: grayscale(100%);
                // -ms-filter: grayscale(100%);
                // -moz-filter: grayscale(100%);
                // -webkit-filter: grayscale(100%);

                &:hover {
                    cursor: pointer;
                    border:1px solid $primary-color;
                }
            }

            input {
                box-shadow: none;
                width: 0;
                height: 0;
                position: absolute;
                z-index: -1;
            }

        }
    }
}

/** thẻ cào **/
.select-method {
    .by_card{
        width: 90%;
        margin: 0 auto;

        @media screen and (max-width: 375px) {
            width: 95%;
        }

        label {
            margin: 0;
            height: auto;
            color: inherit;

            @media screen and (min-width: 425px) {
                width: 30%;
                float: left;
            }

            span {
                color: red;
            }
        }

        .input {
            @media screen and (min-width: 425px) {
                width: 70%;
                float: left;
            }
        }
    }
}


/*** .pay-webmoney ****/
#content {

    .pay-webmoney {
        table{
            width: 50%;
            margin: 0 auto;
            line-height: 30px;

            th{
                font-weight: normal;
                width: 180px;
            }

            td{
                font-weight: bold;
            }
        }
    }
}


/***** .order-information *****/
.order-information {
    .col-right {
        padding: 0;
    }

    .title.blue{
        background-color: #0070BA !important;
        border: none !important;
        color: #ffffff !important;
        border-radius: 2px;
    }

    .column {
        width: 50%;
        float: left;
        padding-right: 20px;
        margin-bottom: 20px;

        table {
            width: 100%;

            td,
            th {
                border: 1px solid #777;
                text-align: center;
                padding: 3px 2px;
                color: #333;
            }

            th {
                background: #999;
                color: #fff;

                &.stt {
                    width: 45px;
                }

                &.price {
                    width: 90px;
                }

                &.qty {
                    width: 30px;
                }
            }

            td {

                &.name {
                    text-align: left;
                }
            }

            tr.total {
                th {
                    background: transparent;
                    text-align: right;
                }

                th,
                td {
                    color: #ec3619;
                    font-weight: bold;
                    border: none;
                }
            }

            .status {
                color: green;
                font-size: 0.9em;
            }
        }

        .price {

            .old {
                color: #aaa;
                text-decoration: line-through;
                font-size: 0.9em;
            }

            .discount {
                color: #ec3619
            }
        }
    }
}

/**** .confirm ****/
.confirm {

    &.order-information {

        .col-right {
            padding-top: 0;
        }

        table {

            &.customer {

                tr:nth-child(odd) th,
                tr:nth-child(odd) td {
                    background: #eee;
                }

                th {
                    background-color: #fff;
                    color: #333;
                    text-align: right;
                    padding-right: 30px;
                    width: 150px;
                    white-space: nowrap;
                }

                @media screen and (max-width: 600px){
                    th{
                        white-space: normal;
                    }
                }

                th,
                td {
                    border: none;
                    border-bottom: 1px solid #ddd;
                    border-top: 1px solid #ddd;
                }

                td {
                    text-align: left;
                }
            }
        }
    }
}
