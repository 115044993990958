.nav-lftborder {
	.nav-link {
		// border-left: 3px solid transparent;
		/*height: 55px;*/
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 10px;
		padding-right: 10px;

		&::before {
			content: "";
			height: 1px;
			float: left;
			width: 100%;
			text-align: center;
			background: -moz-linear-gradient(left, rgba(248,249,250,1) 0%, rgba(221,221,221,1) 50%, rgba(248,249,250,1) 100%);
			background: -webkit-gradient(left top, right top, color-stop(0%, rgba(248,249,250,1)), color-stop(50%, rgba(221,221,221,1)), color-stop(100%, rgba(248,249,250,1)));
			background: -webkit-linear-gradient(left, rgba(248,249,250,1) 0%, rgba(221,221,221,1) 50%, rgba(248,249,250,1) 100%);
			background: -o-linear-gradient(left, rgba(248,249,250,1) 0%, rgba(221,221,221,1) 50%, rgba(248,249,250,1) 100%);
			background: -ms-linear-gradient(left, rgba(248,249,250,1) 0%, rgba(221,221,221,1) 50%, rgba(248,249,250,1) 100%);
			background: linear-gradient(to right, rgba(248,249,250,1) 0%, rgba(221,221,221,1) 50%, rgba(248,249,250,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f9fa', endColorstr='#f8f9fa', GradientType=1 );
		}

		// &:hover {
		// 	color: white;
		// 	background-color: $primary-color;

		// 	&::before {
		// 		height: 0;
		// 	}
		// }

		span {
			padding: .4rem 0;
			display: inline-block;
		}

		&.active {
			// background-color: white;
			// border-left: 3px solid $primary-color;
			color: white;
			background-color: $primary-color;

			&::before {
				height: 0;
			}
		}
	}

	a:first-child {
		&::before{
			height: 0;
		}
	}
}
@media screen and (max-width: 768px) {
	.w-sm-130 {
		width: 130%;
	}

	.sm-text-nowrap {
		white-space: nowrap!important;
	}
}