.callbacks_container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.callbacks {
    position: relative;
    list-style: none;
    overflow: hidden;
    width: 100%;
    padding: 0;
    margin: 0;

    li {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
    }

    img {
        display: block;
        position: relative;
        z-index: 1;
        height: auto;
        width: 100%;
        border: 0;
    }

    .caption {
        display: block;
        position: absolute;
        z-index: 2;
        font-size: 14px;
        text-shadow: none;
        color: #fff;
        background: #000;
        background: rgba(0, 0, 0, .6);
        left: 0;
        right: 0;
        bottom: 0;
        padding: 10px 20px;
        margin: 0;
        max-width: none;
        line-height: 16px;
    }
}

.callbacks_nav {
    position: absolute;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    top: 41%;
    left: 12px;
    opacity: .7;
    z-index: 3;
    border-radius: 20px;
    color: white;
    font-size: 17px;

    &:active {
        opacity: 1;
    }
    &:hover {
        text-decoration: none;
    }

    &.next {
        left: auto;
        background-position: right -10px;
        right: 12px;
    }
}