/***** .edit-profile ****/
#content {
    .edit-profile {
        .c{
            display: inline-block;

            @media screen and (min-width: 600px) {
                padding: 0 15%;
            }

            .member {
                float: left;
                width: 100%;

                label {
                    width: 115px;

                    @media screen and (max-width: 768px) {
                        width: 104px;
                    }
                }

                .input {
                    float: left;

                    &.date {

                        @media screen and (max-width: 768px) {
                            color: white;
                        }
                    }

                    @media screen and (min-width: 768px) {
                        width: calc(100% - 155px);
                    }

                    @media screen and (max-width: 768px) {
                        width: calc(100% - 104px);
                    }

                    span.form-control {
                        background-color: white;
                        border: none;
                        line-height: 30px;
                        padding: 0px;
                    }
                }
                .date {

                    select{
                        width: calc(33.333333% - 3px);
                        float: none;
                        display: inline-block;

                        @media screen and (min-width: 768px) {
                        }

                        @media screen and (max-width: 768px) {
                            min-width: 64px;
                            margin-bottom: 5px;

                            &#MemberBirthdayYear {
                                min-width: 64px;
                            }
                        }
                    }
                }

                .gender {


                    select{
                        width: calc(33.333333% - 3px);
                        float: none;
                    }
                }

                &.error label{
                    color: red;
                    font-style: italic;
                }




                .checkbox {
                    input{
                        width: 20px;
                        height: 20px;
                        float: left;
                        margin-left: 0;

                        @media screen and (max-width: 980px) {
                            height: 16px;
                        }
                    }

                    label{
                        display: inline;
                        font-weight: normal;
                        font-size: 13px;
                        width: auto;
                        line-height: 20px;
                        margin-left: 5px;

                        @media screen and (max-width: 980px) {
                            display: contents;
                            line-height: 20px;
                        }
                    }
                }

            }

            .submit {
                margin: 15px 0;
            }
        }
    }
}

#content .c {
    .des {
        padding: 10px;
        border: 1px solid $border-color-normal;
    }

    .select-method.no-margin-top {
        margin-top: 0!important;
    }
}

.login {
    a{
        color: $text-link;
    }

    label{
        margin-bottom: 5px;
        font-weight: normal;
    }

    .c {
        padding-top: 5px;
    }

    ul {

        li{
            &.name {
                a{
                    color: $text-link;
                }
            }
        }

        a {
            color: #333;

            &:hover{
                text-decoration: underline;
            }

            &.right{
                float: right;
                color: #0882e8;
            }
        }
    }
    .btn{
        width: 100%;
        margin: 10px 0;
        background-color: $button_1;
        border: none;
        text-transform: none;

        &:hover, &:focus{
            background-color: darken($button_1, 5%);
        }
    }

    .login-by-social{
        text-align: center;

        .g-signin2 {
            > div {
                width: 100%!important;
            }
        }

        .f_title{
            position: relative;
            line-height: 20px;

            span{
                padding: 0 10px;
                background: #FFF;
                z-index: 10;
                position: relative;
            }
            &:before{
                content: "";
                width: 100%;
                height: 1px;
                background: #ddd;
                position: absolute;
                left: 0;
                top: 11px;
            }
        }

        span {
            font-size: 13px;
        }

        .btn {
            height: 36px;
            line-height: 26px;
            color: #fff;
            font-family: Roboto-Medium;
            padding:  5px;
            margin: 0;
            font-weight: normal;
            display: inline-block;
        }

        .btn-google-plus{
            background-color: $btn_google_plus;

            svg {
                float: left;
                background-color: #fff;
                width: 20px;
                height: 20px;
            }
        }

        .btn-facebook-login {
            background-color: $btn_facebook;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
            svg {
                width: 26px;
                float: left;
            }

            &:hover {
                -webkit-box-shadow: 0 0 3px 3px rgba(66,103,178,.3);
                box-shadow: 0 0 3px 3px rgba(66,103,178,.3);
            }
        }
    }

    .forget{
        text-align: center;
        font-size: 11px;
        font-style: italic;
        display: inline-block;

        a{
            &:hover{
                text-decoration: underline;
            }
        }
    }

    .f_registration{
        width: 66%;
        float: left;
        margin-bottom: 15px;

        .btn{
            margin: 0;
            width: 130px;

            @media screen and (max-width: 600px){
                width: 150px;
            }
        }
    }
    .registration{
        border-top: 1px solid #ddd;
        width: 100%;

        .btn{
            background-color: #f90;
            color: #FFF;
            height: 34px;
            line-height: 34px;
            padding: 0;
            margin: 0;
            margin-top: 5px;
            display: inline-block;
        }
    }
}

/*//trạng thái vip*/
.level {
    background-color: $button_1;
    width: 30px;
    height: 30px;
    line-height: 32px;
    text-align: center;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;

    /*&.level-current {
        background-color: $primary-color;
    }*/
    &.level-max {
        background-color: #ff9900;
        /*font-size: 1.5em;*/
    }
    &.level-up {
        background-color: #e2e3e7;
    }
    &.lv-bg-gray {
        background-color: #e2e3e7;
    }
}
.lv-progress {
    background-color: $button_1;
    height: 5px;
    display: block;
    margin: 13px 30px;
    width: calc(100% - 30px);


    &.bg-gray {
        background-color: #e2e3e7;
    }

    .lv-progressing {
        height: 5px;
    }

    span {
        white-space: nowrap;
        padding-top: 16px;
        display: inline-block;
    }
}