/**** .paginate ****/
#view-comment .paginate {
    overflow: hidden;
    margin-top: 20px;
}

#view-comment .paginate .loading {
    float: none;
    text-align: right;
}

#view-comment .paginate ul {
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;
}

#view-comment .paginate ul li {
    display: block;
    float: left;
    margin: 0 1px;
}

#view-comment .paginate ul li.page {
    margin-right: 5px;
}

#view-comment .paginate ul li a {
    padding: 2px 5px;
    background: #eee;
    border: 1px solid #ddd;
    color: #333;
}

#view-comment .paginate ul li a:hover,
#view-comment .paginate ul li.active a {
    background: #d9d9d9;
    border: 1px solid #d6d6d6;
    text-decoration: none;
}

/******* .pagination *********/
#content {
    .pagination {
        float:right;

        span.page {
            float: right;
            line-height: 26px;
        }

        ul.pagination {
            list-style: none;
            // margin: 10px 5px 20px 5px;
            padding: 0;
            margin: 0;
            overflow: hidden;
            display: inline-block;
            float: right;

            li {
                float: left;
                margin-left: 2px;
                height: 25px;
                line-height: 25px;

                a {
                    padding: 3px 6px;
                    background-color: #f0f0f0;
                    color: #333;
                    border: 1px solid #e0e0e0;
                    font-size: 14px;

                    &:hover {
                        text-decoration: none;
                        background-color: #a9a9a9;
                        border: 1px solid #a2a2a2;
                        color: #fff;
                    }
                }

                &.current {
                    a {
                        background-color: #a9a9a9;
                        border: 1px solid #a2a2a2;
                        color: #fff;
                    }
                }
            }
        }
    }
}