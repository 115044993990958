.title-no-boder {
  background: none!important;
  border: none!important;
  padding: 0 7.5px;
}

.box-payment-method {

	.item {
		width: 33.333%;
		float: left;
		padding-left: 7.5px;
		padding-right: 7.5px;
		margin-top: 7.5px;
		margin-bottom: 7.5px;

		@media screen and (min-width: 768px) {
			width: 33.33%;
		}

		@media screen and (min-width: 580px) and (max-width: 768px) {
			width: 50%;
		}

		@media screen and (max-width: 580px) {
			width: 100%;
		}

		.item-border {
			min-height: 223px;
			background-color: $background-title;
			border: 1px solid $border-color-normal;
			border-radius: 3px;
		}

		.thumb {
			padding: 5px 10px;
			background-color: white;

			.thumbnail {
				width: 190px;

				&.no-image {
						img {
							width: 105px;
						}
				}
			}
		}

		.info {
			padding: 10px;
			border-top: 1px solid $border-color-normal;

			ul {
				margin-left: 15px;
			}
		}
  	}
}