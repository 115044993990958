#loading {
    position: fixed;
    top: 0;
    z-index: 20000;
    display: none;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    background: url(img/bg_loading.png);
    text-align: center;

    img{
        margin-top: 22%;
    }
}