@charset "UTF-8";

* {
    box-sizing: border-box;
}

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,  article, aside, canvas, details, figcaption, figure, footer, header, menu, nav, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent
}
article, aside, details, figcaption, figure, footer, header, menu, nav, section {
    display: block
}

nav ul {
    list-style: none
}

blockquote, q {
    quotes: none
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none
}
a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent
}

ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none
}

mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold
}

del {
    text-decoration: line-through
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $border-color-normal;
    margin: 1em 0;
    padding: 0
}

.no-magin {
    margin: 0!important;
}

input, select {
    vertical-align: middle;
}

.clear {
    clear: both
}
/*
img.img-responsive {
    max-width: 100%;
    height: auto!important;
}*/

a {
    color: $text-link;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
@font-face {
    font-family: 'Open Sans';
    src:url('fonts/OpenSans-Variable.ttf');
}
@font-face {
    font-family: 'OpenSans-SemiBold';
    src:url('fonts/OpenSans-SemiBold.ttf');
}

body {
    font-family: 'Open Sans';
    margin: 0;
    line-height: 1;
    font-size: 14px;
}

#header,
#main,
#footer .c{
    width: 942px;
    min-width: 300px;
    margin: 0 auto;
}

#wrapper {
  background-color: $background;
}

#main {
    @media screen and (min-width: 500px) and (max-width: 750px) {
        margin-top: 55px;
    }

    @media screen and (max-width: 500px) {
    	margin-top: 43px;
    }

    @media screen and (max-width: 330px) {
        margin-top: 78px;
    }

	.callbacks .caption h3 {
        margin-bottom: 4px;
        line-height: 16px;
        background: none;
        padding: 0;
	}

    .qrcode{
        .info{
            margin: 0 auto 30px;
            font-size: 12px;
        }
        .col-left{
            border-right: 1px solid #e0dfe3;
            padding-right: 15px;
        }
        table{
            margin: 0 auto;
            font-size: 0.9em !important;

            tr{
                border-bottom: 1px solid #eeedf2;

                th{
                    text-align: right;
                    padding: 5px 10px;
                    border-right: 1px solid #eeedf2;
                }

                td{
                    text-align: left;
                    padding: 5px 10px;
                }
            }
        }

        .noti{
            padding: 20px 0 10px;
            p{
                text-transform: uppercase;
                color: #D82D8B;
                margin: 0;
            }
            div{
                background-color: #D82D8B;
                width: 60%;
                margin: 0 auto;
                color: #FFFFFF;
                font-weight: bold;
                text-transform: uppercase;
                padding: 5px;
                margin-top: 5px;
                font-size: 17px;
            }
        }

        .noti2{
            padding: 0 10px;
            p{
                margin: 0;
                color: #D82D8B;
                line-height: 16px;
                font-weight: bold;
                font-size: 0.9em;
            }
        }
    }
}

.text-red {
    color: $text-red;
}

.text-unset {
    text-transform: unset!important;
}


h1 {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 8px;
  }

h2 {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 8px;
}

h3 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 8px;
}

h4 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 8px;
}

h5 {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
}

h6 {
    font-size: 15px;
    line-height: 15px;
    margin-bottom: 8px;
}


/******* #sidebar-left *******/
#sidebar-left {
    float: left;
    @media screen and (min-width: 769px) {
        width: 195px;
        margin-right: -195px;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

/******* #sidebar-right *******/
#sidebar-right {
    width: 200px;
    float: right;
    margin-left: -200px;
}

/******* #content *******/
#content {
    float: left;
    line-height: 21px;
    color: $text-color-normal;

    @media screen and (min-width: 600px) {
        width: calc(100% - 206px);
    }

    @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0 8px;
    }

    @media screen and (min-width: 769px) {
        margin: 0 206px;
    }

    /**** .time_val ****/
    .end-time {
        span{
            display: block;
            line-height: 20px;
            float: left;
            text-align: center;
            margin-right: 2px;
            font-size: 13px;
        }

        .time_val{
            background-color: #999;
            color: #fff;
            border-radius: 2px;
            width: 22px;
        }

        .date{
            padding-right: 5px;
        }

    }

    .related {
        .title {
            span{
                line-height: 30px;
                font-size: 14px !important;
                margin: 0;
            }
        }
    }

    .about-page {
        text-align: justify;
        background-color: #fff;
        padding: 15px 10px 25px;
        border: 1px solid #ddd;
        margin-bottom: 15px;

        .category-banner{
            overflow: hidden;

            div{
                float: left;
                width: 50%;
                text-align: center;

                img{
                    width: 99%;
                }
            }

            @media (max-width: 500px){
                div{
                    width: 100%;
                }
            }
        }

        &.box-social {
            padding: 0px;
            border: none;

            #rrssb-buttons {
                margin: 0;
            }
        }

        ol {
            margin-left: 25px;
        }

        ul {
            margin-left: 25px;
        }

        h3{
            font-size: 14px;
        }

        ul {
            margin-left: 40px;
        }

        hr{
            border: none;
            border-bottom: 1px dotted #DDDDDD;
            margin: 15px 0;
        }

        @media screen and (max-width: 600px){
            &.box-social {
                padding: 5px;
                background-color: #fff7f8;
                border: none;
                border: 1px solid #ff7e27;

                #rrssb-buttons{
                    border: none;
                }
            }

            .label{
                display: none;
            }
        }
    }

    .title {

        div {
            float: left;
            margin-right: 10px;
            line-height: 29px;
            font-size: 14px;
            text-transform: uppercase;
        }
        .back{
            float: right !important;
            display: block;
            padding-right: 10px;
            font-size: 12px !important;
            color: $text-link;
            margin: 0;
            text-transform: capitalize;
            line-height: 29px;
        }
    }


}

/******* .read ******/
.read {
    text-align: justify;
    background-color: #fff;
    border: 1px solid $border-color-normal;
    padding: 10px;
    overflow: hidden;

    a[href='#'] {
        cursor: unset;
        text-decoration: none;
    }

    a {
        text-decoration: none;
    }

    p {
        margin: 8px 0;
    }
}

#content .read ul,
#content .read ol {
    margin-left: 35px;
}
#over {
    display: none;
    background: #000;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: 2000;
}
.text-primary {
    color: $primary-color!important;
}

@media (max-width: 768px) {
    .border-sm-0 {
        border: none!important;
    }

    .select-method {
        .item {
            &.active{
                border: 1px solid #ddd;
                border-top: 0;
                margin-top: 0!important;
                display: inline-block!important;
                width: 100%;
            }
        }

        .title {
            margin-top: 3px!important;
        }
    }

    #v-lftborder-tabContent {
        .title.active{
            background-color:$primary-color!important;

            a {
                color: white!important;
            }
        }
    }
}


#wrapper .tooltip_copy {
    position: relative;
    display: inline-block;

    .btn_copy{
        padding: 1px 7px !important;
        background-position-x: -123px;
    }
    a:hover{
        text-decoration: none !important;
    }

    .tooltiptext {
        visibility: hidden;
        width: auto;
        white-space: nowrap;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 10px;
        box-shadow: 0 0 2px #aaaaaa;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 50%;
        margin-left: -75px;
        opacity: 0;
        transition: opacity 0.3s;

        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: #555 transparent transparent transparent;
        }

    }

    &:hover {
        .tooltiptext {
            visibility: visible;
            opacity: 1;
        }
    }
}
