/******* #header ********/
#bg-header{
    background-color: $primary-color;
}

#header {
    .c {
        position: relative;
        height: 120px;
    }

    .logo-larger {
        position: absolute;
        top: 10%;
        left: 0;
        display: block;
    }

    ul.social li{
        display: block;
        float: left;
        margin-left: 10px;
    }
}