/******* .related *******/
#content {
    #related-item-by-tag-Post {

        .title {
            border: none!important;
            background: none!important;
            padding: 0!important;
        }
        .related  {
             /*margin: 15px 0 10px;*/
            display: inline-block;
            width: 100%;
            border: 1px solid $border-color-normal;
            padding: 10px;

            .c{
                background-color: #fff;
                padding-top: 10px;
            }
            .box {
                width: 50%;
                float: left;
                margin-bottom: 15px;
                min-height: 150px;

                @media screen and (min-width: 738px) and (max-width: 900px) {
                    width: 100%;
                }

                @media screen and (max-width: 768px) {
                    padding-top: 15px;
                }

                @media screen and (max-width: 425px) {
                    width: 100%;
                    border-top: 1px solid #ddd;

                    &:first-child {
                        border-top: none;
                    }
                }

                .bg-box {
                    .thumb {
                        max-width: 108px;
                        /*float: left;*/

                        @media screen and (min-width: 768px) {
                            margin: 0!important;
                        }
                        @media screen and (max-width: 768px) {
                            margin: 0 auto;
                            float: none;
                        }
                    }

                    .info {
                        float: left;
                        margin-left: 15px;
                        width: 63%;

                        @media screen and (max-width: 425px) {
                            width: 100%;
                            margin-left: 0;
                        }

                        @media screen and (max-width: 768px) {
                            width: 100%;
                            margin-left: 0;
                        }

                        .price {
                            @media screen and (max-width: 425px) {
                                float: left;
                            }
                        }

                        .cart-button {
                            margin-top: 10px;

                            @media screen and (min-width: 425px) and (max-width: 768px) {
                                text-align: center;
                            }

                            @media screen and (max-width: 425px) {
                                text-align: right;
                                margin-top: -4px;
                            }

                            .cart-btn {
                                text-align: center;
                            }
                        }
                    }
                }
            }

            .title span {
                font-size: 17px;
            }

            ul {
                list-style-position: inside;

                li {
                    color: #1760b7;
                    padding: 1px 0;
                }
            }
        }
    }
    #related-item {
        .title {
            border: none!important;
            background: none!important;
            padding: 0!important;
        }

        .related  {
             /*margin: 15px 0 10px;*/
            display: inline-block;
            width: 100%;
            border: 1px solid $border-color-normal;
            padding: 10px;

            .c{
                background-color: #fff;
                padding: 10px;
                border: 1px solid $border-color-normal;
            }

            .box {

                @media screen and (min-width: 738px) and (max-width: 900px) {
                    /*width: 100%;*/
                }

                @media screen and (max-width: 768px) {
                    /*padding-top: 15px;*/
                }

                @media screen and (max-width: 425px) {
                    border-top: 1px solid #ddd;

                    &:first-child {
                        border-top: none;
                    }
                }

                .bg-box {
                    .thumb {
                        line-height: 1px;
                        width: 105px;
                        height: 140px;
                        overflow: hidden;
                        margin-bottom: 6px;
                        border-radius: 21px;
                        box-shadow: 0 0 2px #dddddd;
                        border: 1px solid #dddddd;
                        max-width: 108px;

                        @media screen and (min-width: 576px) and (max-width: 768px) {
                            width: 78px;
                        }

                        img {
                            margin: -2px 0 0 -2px;
                            max-width: 107px;
                            width: 107px;
                        }
                    }

                }
            }

            .title span {
                font-size: 17px;
                font-weight: bold;
            }

            ul {
                list-style-position: inside;

                li {
                    color: #1760b7;
                    padding: 1px 0;
                }
            }
        }
    }

}



/**** .related.box-product ****/
#content .related.box-product,
#content .newsletter,
#content .comment-web,
#content .related.box-video {
    padding: 8px 10px 30px;
    border-bottom: 1px solid #eee;
    margin-top: 20px;
    overflow: hidden;
}

#content .related.box-product .title,
#content .related.box-video .title {
    padding-bottom: 10px;
}
