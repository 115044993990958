#cfacebook {
	position:fixed;
	bottom:0px;
	right:30px;
	z-index:999999999999999;
	width:250px;
	height:auto;

	@media screen and (max-width: 767px) {
		display: none;
	}

	.fchat {
		float:left;
		width:100%;
		display: none;
		height:270px;
		overflow:hidden;
		background-color:#fff;

		.fb-page {
			float:left;
		}
	}

	a {
		&.chat_fb {
			color:#fff;
			text-decoration:none;
			height:35px;
			width: 100%;
			display: inline-block;
			line-height:35px;
			background-color: $btn_facebook;
			z-index:9999999;
			font-size:17px;
			padding: 0 10px;
			border-radius: 4px 4px 0 0;

			svg {
				width: 20px;
				float: left;
				margin-top: 7px;
				margin-right: 5px;
			}

			&:hover {
				text-decoration:none;
			}
		}

		#fb_btn {
			float: right;
		}
	}
}

