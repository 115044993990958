/**** .show-cart ****/
.show-cart table.cart{
    width: 100%;
    margin-bottom: 20px;
}
.show-cart table.cart td,
.show-cart table.cart th{
    border: 1px solid $border-color-normal;
    text-align: center;
    line-height: 20px;
    padding: 4px;
}
.show-cart table.cart th{
    background-color: #666;
    color: #fff;
}
.show-cart table.cart td{
    background-color: $background_title;
}

.show-cart #content .password-confirm {
    margin-top: 15px;

    .c{
        padding: 20px 10px;
    }
}
.show-cart {
    label{
        display: inline;
    	height: auto;
    }

    .box-fields {
        .c {
            margin: 0 auto;
            margin-top: 15px;

            @media (min-width: 768px) {
                width: 70%;

                .form-group {
                    label {
                        float: left;
                        min-width: 292px;
                        margin-right: 8px;
                    }

                    .input {
                        float: left;
                        display: inline-block;
                        width: calc(100% - 300px);
                    }
                }
            }
        }
    }
}
.show-cart input.error{
    border: 1px solid $text-error;
    box-shadow: 0px 0px 2px $text-error;
}
.show-cart select.error{
    border: 1px solid $text-error;
    box-shadow: 0px 0px 2px $text-error;
}
.show-cart .password-confirm .submit{
    margin-top: 20px;
}
.show-cart {
    .coupon {
        color: green;
        font-weight: bold;
        overflow: hidden;

        // @media screen and (max-width: 600px){
        //     text-align: center;
        // }

        .col {
            float: left;
            width: auto;

            label{
                float: none !important;
                display: block;
                line-height: 18px;
            }
        }

        .col-l{
            width: 360px;

            @media screen and (max-width:720px) {
                width: 200px;
            }

            @media screen and (max-width:600px) {
                width: 100%;
            }

            a{
                font-style: italic;
                font-weight: normal;
            }
        }


        #coupon {
            display: inline-block;

            // @media screen and (max-width: 600px){
            //     display: inline-block!important;
            //     line-height: 30px;
            // }

            img {
                width: 16px;
                margin-bottom: -3px;
            }
        }

        input[type='text']{
            width: 175px;
            text-transform: uppercase;
            margin-right: 10px;
            float: left;

            @media screen and (max-width:600px) {
                width: calc(100% - 100px);
            }
        }

        .btn{
            height: 30px;
            margin: 0;
        }
    }

    #total-payment{
        text-align: right;
        color: $text-red;
        font-weight: bold;
        // font-size: 14px;
        // margin-top: 23px !important;
        // border-top: 1px solid #eee;
        // padding-top: 13px;

        .discount{
            color: #449B44;
        }
    }
}

.btn-view-order{
    max-width: 280px;
    height: 40px;
    line-height: 27px;
    margin: 10px auto 15px;
    font-size: 17px;
    border-radius: 20px;
    background: none;
    border: 1px solid #3bb3ff;
    color: #3bb3ff;
    font-weight: normal;

    &:hover{
        background-color: #3bb3ff;
        color: white;
    }

    &:focus {
        background-color: #3bb3ff;
        color: white;
    }

    i {
        margin-left: 10px;
    }
}

.text_code_order {
    color: #3bb3ff;
}