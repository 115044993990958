/******* .breadcrumb ********/
.breadcrumb {
    width: 100%;
    background: none;
    padding: 3px 0;
    margin: 0 0 10px 0;
    display: block;

    .social{
        float: right;
        line-height: 8px;
    }

    .icon-spl{
        background-position: -42px -52px;
        margin: 0 5px;
    }

    ul {
        margin: 2px 0 8px 0;
        padding: 0 0 0 2px;
        list-style: none;
        float: left;

        li {
            display: inline;
            font-size: 12px;
            line-height: 15px;
            color: $text-color-normal;
            position: relative;
            display: inline-block;
            float: left;

            &.home {
                a {
                    overflow: hidden;

                    span {
                        padding: 1px 6px 0;
                        background-position: -40px -29px;
                    }
                }
            }

            &.active {
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 260px;
                overflow: hidden;
                display: inline-block;

                @media screen and (min-width: 768px) {

                }
                @media screen and (min-width: 910px) {
                    width: 327px;
                }

                @media screen and (max-width: 425px) {
                    width: 240px;
                }

                @media screen and (max-width: 320px) {
                    width: 130px;
                }

                @media screen and (max-width: 375px) {
                    width: 185px;
                }

            }

            span {
                padding: 0 3px 0 5px;
                color: #777;
            }

            a {
                text-decoration: none;
                color: #777;
                padding: 4px 0;

                :hover {
                    color: #000;
                }
            }

            &:hover {
                a {
                    color: #000;
                }
                /*sub*/
                ul {
                    display: block;

                    a {
                        color: #fff;
                    }
                }
            }
        }
        /*sub*/
        ul {
            position: absolute;
            top: 15px;
            left: 0;
            background: #0070BA;
            z-index: 999;
            padding: 0;
            border-radius: 3px;
            overflow: hidden;
            display: none;

            li {
                display: block;
                margin-left: 0;

                &:hover {
                    background: #0f7fc4;
                }

                .icon-spl{
                    background-position: -21px -52px;
                }
            }

            a {
                color: #fff;
                display: block;
                width: 300px;
                line-height: 16px;
                padding: 5px 4px;
                border-bottom: 1px solid #0f7fc4;

                &:hover {
                    color: #000;
                }

                span {
                    background-position: -0px -52px;
                    padding: 0 8px;
                }

                &:hover {
                    span {
                        background-position:-19px -52px;
                    }
                }
            }
        }
    }
}
