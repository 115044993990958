/*** .counter ***/
.sidebar {
    .box {
        &.counter {
            table {
                width: 100%;
                margin: 0 auto;

                tr {
                    th {
                        font-weight: normal;
                        text-align: left;
                        font-size: 12px;
                        padding: 5px 0;
                        color: #555;
                    }

                    td {
                        font-size: 12px;
                        padding: 5px 0;
                        color: #555;
                        text-align: right;
                    }

                    &.online {
                        th {
                            font-weight: bold;
                        }

                        td {
                            font-weight: bold;
                        }
                    }

                    &.total_top {
                        th {
                            border-bottom: none;

                            p {
                                text-align: center;
                                font-weight: bold;
                                height: 30px;
                                line-height: 30px;
                                margin-bottom: 5px;
                                font-size: 19px;
                                text-shadow: 1px 1px 1px #fff;
                                background: #eee;
                                border: 1px solid #ddd;

                                span {
                                    padding: 0 5px;
                                }
                            }
                        }

                        td {
                            border-bottom: none;
                        }
                    }

                    &.total {
                        th {
                            border-bottom: none;
                            border-top: 2px solid #ddd;
                            font-weight: bold;
                            color: #333;
                            padding-top: 5px;
                        }

                        td {
                            border-bottom: none;
                            border-top: 2px solid #ddd;
                            font-weight: bold;
                            color: #333;
                            padding-top: 5px;
                        }
                    }
                }
            }
        }
    }
}