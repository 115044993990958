
/**** ***/
#momo-pay {
    margin: 40px 0;

    i[class^="icon-"] {
        background-image: url("img/icon.png");
        background-repeat: no-repeat;
        padding: 4px 11px;
        margin-right: 8px;

        &.icon-money{
            background-position: -163px -252px;
        }
        &.icon-info{
            background-position: -191px -252px;
        }
        &.icon-cart{
            background-position: -220px -252px;
        }
    }


    .m-title {
        display: block;
        margin-bottom: 10px;
        font-weight: 700;
    }
    .col-l {
        width: 200px;
        float: left;
        margin-right: -200px;
        background-color: #0070BA;
        padding: 20px 10px;
        color: #ffffff;
        border-radius: 3px;

        .m-row {
            border-bottom: 1px solid #0069aa;
            padding: 17px 0;

            &:first-of-type,
            &:first-of-type .c{
                text-align: center;
                padding-left: 0;
                font-size: 1.1em;
            }

            &:last-of-type{
                border-bottom: none;
                /*text-align: center;*/
                /*padding: 20px 0;*/
            }
        }

        a{
            color: #ffffff;
        }

        .c{
            padding-left: 30px;
        }
    }

    .col-r{
        margin-left: 240px;

        .logo-mono{
            overflow: hidden;
            margin-bottom: 20px;
            border-bottom: 1px solid #dddddd;
            padding-bottom: 15px;

            img{
                width: 40px;
            }

            .l{
                float: left;
            }

            .r{
                float: right;
            }
        }

        .c{
            text-align: center;

            .qr {
                img{
                    width: 250px;
                }
            }
        }

        .m-title{
            color: #B0006E;
        }

        .icon-scan{
            background-position: -191px -284px;
        }
    }

}
