/****** .filter ******/
.filter {
    #content {
        .title {
            h1 {
                font-size: 15px;
                color: #333333;
                text-transform: none;
                font-weight: bold;
            }
            border: none;
            background: none;
            padding: 0;
        }

        .box-post {
            .box {
                .bg-box {
                    margin: 10px;
                }
            }
        }

        .box-product {
            .name {
                height: 60px;
            }
        }
    }

    &.all {
        .name {
            a {
                font-weight: normal;
            }
        }

        .link {
            color: #999;
            font-size: 13px;
            line-height: 15px;
        }
    }

    .search {
        overflow: hidden;

        .keyword {
            margin-right: -180px;
            float: left;
            padding-right: 95px;
        }

        .cate {
            margin-right: 5px;
            float: left;
            height: 24px;
            margin: 10px 2px 20px 0;
            border: 1px solid #ddd;
            background-color: #f9f9f9
        }

        .price {
            float: left;
            height: 24px;
            margin: 10px 2px 20px;
            border: 1px solid #ddd;
            background-color: #f9f9f9;
        }

        /*.btn {
            height: 30px;
            float: right;
            margin: 0;
        }*/
    }
    ul.tab-filter {
        list-style: none;
        margin: 8px 0 5px;
        padding: 0;
        overflow: hidden;
        border-bottom: 1px solid #ebebeb;

        li {
            padding: 8px 8px 8px;
            display: inline;
            float: left;
            text-align: center;
            margin-right: 20px;
            border-bottom: 3px solid #F5F5F5;

            &.active {
                border-bottom: 3px solid #dd4b39;
                font-weight: bold;

                a {
                    color: #dd4b39;
                }
            }

            a {
                font-size: 14px;
                color: #888;

                &:hover {
                    text-decoration: none;
                    color: #333;
                }
            }
        }

        @media screen and (max-width: 500px) {
            li {
                padding: 8px 2px 8px;
            }
        }
    }


    .box-filter{
        .box{
            overflow: hidden;
            margin-bottom: 20px;
            border: 1px solid #dddddd;
            border-radius: 8px;
            padding: 10px 5px 5px 5px;

            .img-responsive{
                width: 100%;
            }
            .thumb{
                float: left;
                width: 100px;
                overflow: hidden;
                margin-right: 20px;
                margin-right: -100px;

                img{
                    line-height: 1px;
                }
                a{
                    line-height: 1px;
                }
            }
            .info{
                float: left;
                margin-left: 120px;
            }
            .name {
                margin-bottom: 10px;
                a {
                    color: #666666;
                    font-size: 16px;
                    
                }
            }
        }
    }
}

/**** .filter *****/
#content {
    ul {
        &.filter {
            overflow: hidden;
            margin: 5px 0 10px;

            li {
                list-style: none;
                float: left;
                width: 32.58%;
                margin-right: 10px;

                @media screen and (max-width: 768px) {
                    width: 32.3%;
                }

                @media screen and (min-width: 425px) and (max-width: 768px) {
                    margin-right: 6px;
                }

                @media screen and (max-width: 425px) {
                    margin-right: 3px;
                }

                &:last-child {
                    margin-right: 0;
                }

                input,
                select{
                    width: 100%;
                }
            }
        }
    }
}

.filter-inner {
    position: relative;
    z-index: 1;

    @media screen and (min-width: 768px) {
        &.filter-short {
            float: right;
            width: 305px;
            margin-top: -34px;
        }
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        float: left;
        margin-bottom: 10px;
    }

    &.filter-post {
        margin-top: -7px;
    }
}

/* overwrite ui-css*/
.ui-corner-all {
    border-radius: 0 0 4px 4px;
}

.ui-widget-content {
    background-color: white;
    width: 305px;
}