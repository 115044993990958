

/******* .box-post ********/
#content .box-post .box {
    width: 50%;
    float: left;
    margin-bottom: 7px;
    padding-bottom: 5px;
    border-bottom: 1px solid #f0f0f0;
}

.detail-product #content .box-post .box,
.home #content .box-post .box {
    width: 100%;
    margin-top: 0;
}

.detail-product #content .box-post .box .bg-box,
.home #content .box-post .box .bg-box {
    height: auto;
}

.detail-product #content .box-post {
    margin-top: 0;
}

#content .box-post .box .bg-box {
    margin: 3px 0;
    height: 126px;
    overflow: hidden;
}

#content .box-post .box.odd .bg-box {
    margin-right: 15px;
}

.detail-product #content .box-post .box .bg-box,
.home #content .box-post .box.odd .bg-box {
    margin-right: 0;
}

#content .box-post .box .name {
    margin: 0;
}

#content .box-post .box .name a {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    display: block;
    font-weight: normal;
}

#content .box-post .box .thumb {
    float: left;
    margin-right: 10px;
    width: 100px;
}

#content .box-post .box .thumb img {
    border-radius: 2px;
}

#content .box-post .box .thumb img:hover {
    opacity: 0.8;
}

#content .box-post .box .thumb a {
    line-height: 1px;
    display: block;
}

#content .box-post .box .info .date-time {
    font-size: 12px;
    color: #888;
    line-height: 15px;
    font-style: italic;
    margin-top: 2px;
}

#content .box-post .box .info .date-time .icon {
    background: url(img/icon.png) -68px -31px no-repeat;
    padding: 0 8px;
}

#content .box-post .box .summary {
    text-align: justify;
    margin-top: 3px;
}

#content .box-post.run{
    padding-bottom: 20px;
    border: 1px solid #ddd;
    margin-bottom: 12px;
}

#content .box-post .title .more{
    float: right;
    font-size: 13px !important;
    color: $text-link;
    text-transform: none;
    font-weight: normal;
    margin-right: 3px;
}

#content .box-post.run .bg-post-run{
    padding: 0 41px 0 45px;
    position: relative;
    height: 120px;
    overflow: hidden;

    @media screen and (max-width: 375px) {
        padding: 0 21px 0 25px;
    }
}

#post-run {
    .item {
        height:120px;
        margin: 0 5px;
        float: left;
        padding: 15px 10px;
        border-radius: 2px;

        @media screen and (min-width: 768px) {
            width: 155px;
        }

        a {
            color: #333;
            font-size: 13px;
            float: left;
            width: 100%;
        }

        .thumb {
            width: 100%;
            height: 65px;
            overflow: hidden;

            img{
                border-radius: 2px;
            }
        }

        .name {
            text-align: center;
            display: block;
            height: 31px;
            overflow: hidden;
            line-height: 15px;
        }
    }
}

