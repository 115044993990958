/*.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;

    >tbody {
        >tr {
            >td,
            >th {
                padding: 8px;
                line-height: 1.42857143;
                vertical-align: middle;
            }
        }
    }

    >tfoot {
        >tr {
            >td,
            >th {
                padding: 8px;
                line-height: 1.42857143;
                vertical-align: center;
            }
        }

    }

    >thead {
        >tr {
            >td,
            >th {
                padding: 8px;
                line-height: 1.42857143;
                vertical-align: top;
            }
        }
    }
}

.table-responsive {
    min-height: .01%;
    overflow-x: auto;
}

@media screen and (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd;

        >.table {
            margin-bottom: 0;

            >tbody {
                >tr {
                    >td,
                    >th {
                        white-space: nowrap;
                        height: 35px;
                    }
                }
            }

            >tfoot {
                >tr {
                    >td,
                    >th {
                        white-space: nowrap;
                    }
                }

            }

            >thead {
                >tr {
                    >td,
                    >th {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
*/


table.title-click{
    width: 100%;
}
table.title-click td{
    font-size: 14px !important;
}
table.title-click .small{
    width: 1px;
    white-space: nowrap;
}
table.title-click .small.l{
    text-transform: uppercase;
    padding-right: 5px;
}