/************************************************/
/************************************************/
/************************************************/
@media all and (max-width: 980px) {
    /******* #header,#footer,#main *******/
    #header,  #main, #footer .c {
        width: 99%;
    }

    #header .logo-larger{
        top: 26px;
        left: 5px;
    }
    #header .logo-larger img{
        height: 55px;
    }

    #nav-right{
        right: 5px;
    }

    /**** .search-top ****/
    .search-top {
        width: 300px;
        top: 40px;
        right: 5px;
    }

    /******* .box-product ********/
    #content .box-product .box {
        width: 25%;
    }

    .home #content .box-product .box {
        width: 50%;
    }

    /**** .list-post *****/
    #content .box-post .box .thumb {
        width: 75px;
    }

    /***** .breadcrumb .social *****/
    .breadcrumb .social{
        display: none;
    }
}

/* Dành cho máy tính bảng, hiển thị chiều ngang */
@media all and (max-width: 750px) {
    #header .c{
        height: auto;
    }
    /******* .icon-menu ********/
    .nav-mobile {
        display: block;
    }

    #nav-desktop,#nav-right,#header .logo-larger {
        display: none;
    }

    /**** .search-top ****/
	.search-top{
		top: 6px;
        width: 170px;
        right: 72px;

		.search-inner {
            input {
                height: 25px;
                margin-top: 2px;
                border-radius: 50px;
                padding: 12px;
            }
        }
		&.fix-position {
			position: fixed;
			z-index: 1001;
		}
	}


    /******* #sidebar-right *******/
    #sidebar-right {
        display: none;
    }

    /******* #content *******/
    #content {
        margin-right: 0;
    }

    /**** .box-product *****/
    #content .box-product .box {
        width: 33.333%;
    }

    #content .box-product-category .box{
        width: 25%;
    }

    .cart-button.center a.btn {
        width: 100%;
    }

    /**** .list-post *****/
    #content .box-post .box {
        width: 100%;
    }

    #content .box-post .box .bg-box {
        height: auto;
    }

    #content .box-post .box.odd {
        margin-right: 0;
    }

    /***** .detail-product *****/
    .product-image {
        width: 100%;
        float: none;
        margin-bottom: 10px;
    }

    .product-image #slider-product-pager li {
        width: 16.6666%;
    }

    .product-image #slider-product-pager li a img {
        width: 95% !important;
    }

    .detail-product #content .info-product {
        width: 100%;
    }

    /**** .all ****/
    .filter.all .link {
        display: none;
    }

    .filter .search .cate,
    .filter .search .price {
        width: 48%;
    }

    /**** .alert ****/
    .alert {
    }

    /******* .box-video ********/
    #content .box-video .box {
        width: 33.3333%;
    }

    /***** .order-information *****/
    .order-information .col {
        width: 100%;
        padding: 0;
    }

    /***** .comment-web-rate *****/
    .comment-web-rate .box1{
        width: 45%;
    }
    .comment-web-rate .box2{
        width: 50%;
        border-right: none;
    }
    .comment-web-rate .box3{
        width: 100%;
    }
    .comment-web-rate .box3 .btn{
        margin: 0 auto;
    }

    iframe {
        max-width: 100%;
        height: auto;
    }
}

@media all and (max-width: 680px) {
    #content .box-product-category .box{
        width: 33.333%;
    }
}

/* Dành cho máy tính bảng, hiển thị chiều dọc */
@media all and (max-width: 600px) {
    /******* #sidebar-left *******/
    #sidebar-left {
    }

/*
    #content .box-product-category .box{
        width: 25%;
    }
*/
    /******* .slideshow.position-right *******/
    .slideshow.position-right {
        margin-left: 0
    }

    /******* #content *******/
    #content {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    /**** #popup_cart *****/
    #popup #popup_cart {
        width: 98% !important;
    }

    #popup_cart .thumb {
        display: none;
    }

    #popup_cart .price1 {
        display: none;
    }

    /******* .box-video ********/
    #content .box-video .box {
        width: 50%;
    }
}

/* Dành cho điện thoại di động, hiển thị chiều ngang */
@media all and (max-width: 450px) {
    #content .box-post .box .thumb {
        float: left;
        margin-right: 10px;
        width: 70px;
    }

    /***** .detail-product *****/
    .product-image #slider-product-pager li {
        width: 20%;
    }

    #content .box-product-category .box{
        width: 50%;
    }

    /***** .slideshow *****/
    .slideshow {
        display: none;
    }

    /******* .box-video ********/
    #content .box-video .box {
        width: 100%;
    }


    /*** footer ***/
    /*#footer .box{
        width: 100% !important;
        float: none !important;
        margin: 0 0 20px !important;
    }
    #footer .box .title{
        margin: 0 0 5px;
    }*/
}

/* Dành cho điện thoại di động, hiển thị chiều dọc */
@media all and (max-width: 360px) {
    /**** .box-product *****/
    #content .box-product .box {
        width: 50%;
    }

    .nav-mobile .logo{
        width: 76px;
        /*left: 30px;*/
        top: 9px;
    }

    .home #content .box-product .box {
        width: 100%;
    }

    /*** ul.tab-filter ****/
    ul.tab-filter li {
        margin-right: 5px;
    }
}