/***** .list_category *****/
#content {
    .list_category {
        margin: 0 0 15px;
        overflow: hidden;
        border: 1px solid $border-color-normal;
        padding: 6px 5px;
        background-color: #fff;

        .thumb{
            width: 30px;
            margin-right: 5px;
            float: left;

            a {
                //height: 48px;
                display: block;
                line-height: 1px;
                width: 30px;
                height: 40px;
                overflow: hidden;
                margin-bottom: 6px;
                border-radius:  5px;
                -webkit-border-radius:  5px;
                -moz-border-radius:  5px;
                -ms-border-radius:  5px;
                -o-border-radius:  5px;
                border: 1px solid #ddd;

                img {
                    width: 34px;
                    max-width: 34px;
                    margin: -2px 0 0 -2px;
                }
            }
        }

        .filter {
            margin: 5px 5px 10px;
        }
        .item {
            float: left;
            padding: 5px;
            width: 33.33%;
            border-radius: 3px;

            @media screen and (max-width: 425px){
                height: 76px;
            }

            @media screen and (max-width: 600px){
                width: 50%;
            }

            .c{
                border-radius: 3px;
                border: 1px solid $border-color-normal;
                overflow: hidden;
                padding: 7px 5px 0px;
                // height: 100%;

                &:hover {
                    background-color: #ddd;
                }

                a {
                    display: block;
                }

                .info {
                    .name {
                        // &.text-middle {

                        //     @media screen and (min-width: 1200px){
                        //         line-height: 37px;
                        //     }

                        //     @media screen and (max-width: 1200px){
                        //         line-height: 20px;
                        //     }
                        // }
                    }
                }
            }

            span {
                padding: 0 7px;
                background: url(img/icon.png) -65px -50px no-repeat;
            }

            a {
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

