/**** .tabs ****/
#content ul.nav-tabs {
    margin: 0 0 -2px 0;
    padding: 0;
    list-style: none;
    height: auto;
    overflow: hidden;

    li {
        display: block;
        float: left;
        // margin-bottom: 10px;

        a {
            display: block;
            padding: 0 10px;
            height: 40px;
            line-height: 40px;
            position: relative;
            font-weight: bold;
            color: $text-color-normal;
            transition: all .3s ease;
            -webkit-transition: all .3s ease;

            &::before{
                content: " ";
                position: absolute;
                left: 0;
                bottom: 5px;
                right: 0;
                width: 0;
                margin: auto;
                height: 3px;
                background: $primary-color;
                -webkit-transition: all .3s ease;
                transition: all .3s ease;
            }

            &:hover{
                text-decoration: none;
                color: $primary-color;
                transition: all .3s ease;
                -webkit-transition: all .3s ease;

                &::before {
                    width: calc(100% - 25px);

                }

            }

            &.active {
                color: $primary-color;
                font-weight: bold;
                background-color: $background_title;
                border: 1px solid #ddd;
                border-bottom: none;

                &::before{
                    width: calc(100% - 25px);
                }
            }
        }

    }

    &.nav-history {
        .nav-item {
            a {

                @media screen and (max-width: 768px) {
                    padding: 0 4px;
                }
            }
        }
    }

    @media screen and (max-width: 600px){
        a{
            font-size: 12px;
        }
    }
}

.tab-content {
    .tab-pane {
        display: none;
        padding: 0 0 8px;

        &.active {
            display: block;
        }
    }
}
