/***** .detail-order ****/
.detail-order {
    padding-top: 0;

    &.message {
        padding-top: 10px;
    }

    table{
        max-width: 1000px !important;
    }
    h2{
        font-size: 14px;
        background-color: #1A7FC1;
        color: #fff;
        padding: 5px;
        text-align: center;
        text-transform: uppercase;
        margin-top: 15px;
    }

    table {
        width: 100%;
        line-height: 20px;

        th {
            border: 1px solid $border-color-normal;
            padding: 5px;
        }

        td {
            border: 1px solid $border-color-normal;
            padding: 5px;
        }

        .center {
            text-align: center;
            vertical-align: middle;
        }

        &.t1 {
            margin-top: 15px;

            th{
                width: 150px;
            }
        }

        &.t2 {
            th{
                background-color: $background_title;
            }
        }

        .stt{
            width: 55px;
        }
    }

    .message {
        min-height: 125px;
        display: inline-block;
        width: 100%;
        border: 1px solid $border-color-normal;
        padding: 0 6px;
        margin: 0;
    }
}

.thank {
    &.order-information {
        #content {
            .title {
                h1 {
                    font-weight: normal;
                }
            }
        }
    }
}

/*** .info-mccash ***/
#content .info-mccash {
    padding: 0 20px 10px;
    overflow: hidden;
    font-weight: bold;
}

#content .info-mccash [class*="col-"] {
    width: 50%;
    float: left;
}

#content .info-mccash .input {
    border: 1px solid #aaa;
    background-color: #f0f0f0;
    width: 150px;
    padding: 2px 8px;
    float: left;
    border-radius: 2px;
}

#content .info-mccash div {
    margin: 2px 0;
}

#content .info-mccash .link {
    float: left;
    margin-left: 10px;
    padding-top: 3px;
}

/***** .my-order ****/
#content {
    .my-order {
        width: 100%;

        a.btn-readmore {
            height: 25px;
            line-height: 15px;
            background-color: $button_2;
            width: 120px;
            color: white;
            display: inline-block;

            &:hover {
                color: white;
            }
        }

        tr {
            &:nth-child(odd) {
                background-color: #f9f9f9;
            }

            &:hover {
                background-color: #e9e9e9;
            }

            td,
            th {
                border: 1px solid $border-color-normal;
                padding: 5px 4px;
                text-align: center;
            }

            th {
                background: #f0f0f0;

                &.medium {
                    width: 170px;
                }
            }
        }
    }

    .code{
        color: $text-link;

        .date_time {
            font-size: 0.77em;
            margin: 0;
            color: $text-color-normal;
        }
    }
}

.password-confirm {
    .c {
        .input {
            label {
                 margin-right: 5px;
            }

            input {
                float: left;

                @media screen and (min-width: 600px) {
                    width: 35%;
                }

                @media screen and (max-width: 600px) {
                    float: none;
                    width: 50%;
                    margin: 0 auto;
                }
            }
        }
    }
}
