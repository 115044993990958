/***** .form-forget-password ****/
.form-forget-password{
	margin: 0 auto !important;
	width: 100%;

	@media screen and (min-width: 900px) {
		.form-group {
			label {
				width: 35%;
				display: inline-block;
			}

			> div {
				width: 65%;
				display: inline-block;
			}
		}
  	}
	@media screen and (min-width: 375px) {
  		width: 76%;
  	}

	input {
		width: 100%;
	}

	.btn{
		max-width: 200px;
	}

	.submit{
		text-align: center;
	}

	.captcha {

		label {
			@media screen and (min-width: 768px) and (max-width: 900px) {
		    	width: 100%;
			}

		  	@media screen and (max-width: 690px) {
		    	width: 100%;
		  	}
		}

		#inner_captcha {
			@media screen and (min-width: 900px) {
				width: 100%;
				text-align: center;
			}
		}

		.input {

			@media screen and (min-width: 900px) {
				// float: left;
				// margin-top: 19px;
				// margin-right: 15px;
			}

			@media screen and (min-width: 690px) and (max-width: 767px) {
				float: left;
				margin-top: 19px;
				margin-right: 15px;
			}

			@media screen and (min-width: 768px) and (max-width: 900px) {
				display: inline-block;
				text-align: center;
				width: 100%;
			}

			@media screen and (max-width: 690px) {
				display: inline-block;
				text-align: center;
				width: 100%;
			}
		}

		#inner_captcha {
			@media screen and (min-width: 768px) and (max-width: 900px) {
				text-align: center;
			}

			@media screen and (max-width: 690px) {
				text-align: center;
			}
		}
	}
}