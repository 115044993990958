/**** .search-top ****/
.search-top {
    position: absolute;
    top: 31%;
    right: 0px;
    width: 305px;

    .keyword{
        width: 100%;
        border: none !important;
        background: #ffffff url("img/icon.png") -158px -181px no-repeat;
        padding-left: 25px;
        position: relative;
        z-index: 10000;
    }

    #search_result{
        background-color: #ffffff;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        z-index: 1000;
        overflow: auto;
        max-height: 500px;
        padding: 10px;
        box-shadow: 0 2px 4px #333333;
        border-radius: 0 0 2px 2px;
        display: none;

        ul{
            list-style: none;
            li{
                border-bottom: 1px solid #eeeeee;
                overflow: hidden;

                &:hover{
                    cursor: pointer;
                    color: #0070BA;
                    background-color: #f1f1f1;
                }

                &:last-of-type{
                    border-bottom: none;
                }

                img{
                    width: 40px;
                    float: left;
                    margin-right: 10px;
                }
                a{
                    line-height: 18px;
                    color: #555555;
                    display: block;
                    overflow: hidden;
                    padding: 4px;

                    &:hover{
                        text-decoration: none;
                        color: #0070BA;
                    }
                }

                &.more{
                    text-align: center;
                    padding-top: 10px;
                    .btn{
                        margin: 0 auto;
                        padding: 7px 30px;

                        &:hover{
                            background-color: #0070BA;
                            color: #ffffff;
                        }
                    }

                    &:hover{
                        background: none;
                    }
                }
            }
        }
    }

    #search_result-mark{
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
    }
}
@media screen and (max-width: 768px) {
    .search-top{
        .keyword{
            width: 26px;
            height: 26px;
            margin-top: 2px;
            float: right;
            margin-right: 20px;
            border-radius: 20px;
            padding-left: 22px;
        }

        &.show{
            .keyword{
                width: 200px;
                position: fixed;
                top: 3px;
                height: 30px;
                left: 2%;
                width: 96%;
                border-radius: 2px;
                transition: all 0.1s;
            }

            #search_result{
                position: fixed;
                top:40px;
                left: 0;
                width: 100%;
                z-index: 100000;
            }
        }
    }
}

//.search-inner {
//    list-style: none;
//    display: inline-block;
//    padding: 0;
//    width: 100%;
//    margin: 0;
//
//    @media screen and (max-width: 768px) {
//        width: 20px;
//        float: right;
//        margin-right: 20px;
//    }
//
//    .fa-search {
//        position: absolute;
//        top: 7px;
//        width: 16px;
//        height: 16px;
//        margin-left: 7px;
//        font-size: 16px;
//        color: $primary-color;
//    }
//
//    input{
//        width: 100%;
//        display: inline-block;
//		padding: 0 20px 0 25px;
//    }
//
//    #sClear {
//        width: 12px;
//        height: 12px;
//        position: absolute;
//        top: 8px;
//        right: 5px;
//
//        &:hover {
//            cursor: pointer;
//        }
//    }
//}