/******* .sidebar ********/
.no-sidebar-right {
    #content {
        margin-right: 0;
    }
}

.no-sidebar-left {
    #content {
        margin-left: 0;
    }
}

.no-sidebar-right.no-sidebar-left {
    #content {
        width: 100%;
    }
}
.sidebar {
    /*** .facebook ***/
    .facebook,
    .googleplus,
    .pinterest {
        border: none;
        padding: 0;
    }

    .product {
        .caroufredsel_wrapper {
            height: 420px !important;
            margin: 0 !important;
        }

        .info {
            text-align: center;
        }

        .thumb {
            position: relative;

            .discount {
                height: 29px !important;
                padding-top: 9px !important;
                padding-top: 8px;
            }

        }

        li {
            padding-bottom: 15px;
        }

        img {
            width: 100%;
            height: auto;

            &:hover {
                opacity: 0.9;
            }
        }

        a{
            &:hover {
                text-decoration: underline;
            }
        }

    }
    /*post*/
    .post {
        .caroufredsel_wrapper {
            height: 228px !important;
            margin: 0 !important;
        }

        li {
            margin-bottom: 8px;
            padding-bottom: 7px;
            border-bottom: 1px solid #eee;

            a {
                color: #333;
                font-size: 0.9em;
                line-height: 16px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .thumb {
            width: 41px;
            float: left;
            margin-right: 5px;
        }
    }

    /*category*/
    .category {
        .c {
            ul {
                li {
                    border-bottom: 1px solid #F2F2F2;
                    position: relative;

                    &:hover {
                        background: #ddd;

                        ul {
                            display: block;

                            ul {
                                display: none;
                            }
                        }
                    }

                    a {
                        display: block;
                        padding: 7px 6px 5px 7px;
                        color: #333;

                        span {
                            background: url(img/icon.png) -44px -50px no-repeat;
                            padding: 0 6px;
                        }
                    }

                }

                ul {
                    padding: 1px;
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 178px;
                    z-index: 1000;
                    background: #ddd;

                    li {
                        border-bottom: 1px solid #EDEDED;
                        position: relative;

                        &:hover {
                            background: #fff;

                            ul {
                                display: block;
                            }
                        }
                    }

                    a {
                        width: 180px;
                    }

                    ul {
                        left: 193px;
                        background: #fff;

                        li {
                            &:hover {
                                background-color: #DDDDDD;
                            }
                        }
                    }
                }
            }
        }
    }

    .newsletter {
        .des {
            font-size: 0.9em;
            color: #777;
            text-align: center;
            line-height: 15px;
            margin-bottom: 10px;
        }
    }

    .support{
        color: #333;
        line-height: 20px;
        font-size: 13px;

        /*div{
            margin-bottom: 5px;
            padding-left: 25px;
            position: relative;
        }

        [class*="icon-"]{
            background-image: url(img/login_social.png);
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 20px;
            height: 20px;
        }*/

        span{
            color: $text-link;
            font-weight: bold;
        }

        /*.icon-email{
            background-position: 1px -334px;
        }

        .icon-phone{
            background-position: 1px -388px;
        }

        .icon-time{
            background-position: 1px -465px;
        }*/
    }


    @media screen and (max-width: 768px) {
        .sidebar-dropdown {
            &.open {
                .title {
                    padding-bottom: 10px;
                    border-bottom: 1px solid #ccc;

                    .fa-chevron-up {
                        transform: rotate(0);
                        transitions: 0.5s;
                        -webkit-transition: 0.5s;
                        -moz-transition: 0.5s;
                        -ms-transition: 0.5s;
                        -o-transition: 0.5s;
                    }
                }

                .c {
                    display: block;
                    transitions: 0.5s;
                    -webkit-transition: 0.5s;
                    -moz-transition: 0.5s;
                    -ms-transition: 0.5s;
                    -o-transition: 0.5s;
                }
            }

            .title {
                .fa-chevron-up {
                    transform: rotate(180deg);
                    transitions: 0.5s;
                    -webkit-transition: 0.5s;
                    -moz-transition: 0.5s;
                    -ms-transition: 0.5s;
                    -o-transition: 0.5s;
                }
            }

            .c {
                display: none;
                -webkit-transition: 0.5s;
                -moz-transition: 0.5s;
                -ms-transition: 0.5s;
                -o-transition: 0.5s;

            }
        }
    }

    .box {
        border: 1px solid $border-color-normal;
        background: #fff;
        margin-bottom: 8px;

        @media screen and (min-width: 767px) {
            padding-bottom: 10px;
        }

        @media screen and (min-width: 600px) and (max-width: 767px) {
            width: 31.4%;
            float: left;
            margin: 5px;
        }
        @media screen and (min-width: 426px) and (max-width: 600px) {
            width: 47.4%;
            float: left;
            margin: 5px;
        }
        @media screen and (max-width: 425px) {
            width: 100%;
        }

        &.adv .c {
            padding: 10px 2px 0;
        }

        .title {
            background-color: $background_title;
            display: block;
            text-align: center;
            height: 30px;
            line-height: 31px;
            color: $text-color-normal;
            font-weight: bold;

            @media screen and (min-width: 767px) {
                border-bottom: 1px solid #ccc;
            }

            @media screen and (min-width: 768px) {
                .fa-chevron-up {
                     display: none;
                 }
            }
            @media screen and (max-width: 768px) {
                .fa-chevron-up {
                    float: right;
                    line-height: 30px;
                    padding: 0 6px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        .c {
            padding: 10px 10px 0;
        }
    }

    a {
        text-decoration: none;
    }
}