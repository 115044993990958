/*** .nav-top***/
#content .c-nav-top{
    overflow: hidden;
}
#content .c-nav-top ul.nav-sort{
    list-style: none;
    float: left;
    white-space: nowrap;
}
#content .c-nav-top ul.nav-sort li{
    display: inline;
    padding-right: 5px;
}
#content .c-nav-top ul.nav-sort a{
    padding: 7px 16px;
    border: 1px #0070BA dashed;
    line-height: 50px;
    border-radius: 5px;
    font-weight: bold;
    color: #0070BA;
}
#content .c-nav-top ul.nav-sort a:hover,
#content .c-nav-top ul.nav-sort .current a{
    background-color: #0070BA;
    text-decoration: none;
    color: #ffffff;
}

/*** .box-gear ****/
#content .box-gear img.img-responsive {
    width: 100%;
    height: auto;
}
#content .box-gear{
    margin: 10px 0 20px;
    padding-bottom: 1px;
}
#content .box-gear .box{
    width: 20%;
    float: left;
    border: 1px solid #eeeeee;
    margin-right: -1px;
    margin-bottom: -1px;
    padding: 10px 8px;
    position: relative;
}
.home #content .box-gear .box{
    width: 25%;
}

#content .box-gear .box .thumb{
    overflow: hidden;
    margin-bottom: 2px;
}
#content .box-gear .box img{
    height: auto;
    transition: 1s;
    max-width: 360px;
}
#content .box-gear .box:hover img{
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2);
}
#content .box-gear .box .bg-box{
    padding: 10px;
    text-align: center;
}
#content .box-gear .box .name{
    height: 44px;
    overflow: hidden;
    margin-bottom: 5px;
}
#content .box-gear .box .name a{
    color: #333333;
}
#content .box-gear .box .price span{
    font-weight: bold;
}
#content .box-gear .box .old .p{
    text-decoration: line-through;
}
#content .box-gear .box .discount{
    color: #ff424e;
    font-weight: normal !important;
    font-size: 13px;
}
#content .box-gear .box .new{
    font-size: 1.4em;
    margin-top: 10px;
    display: block;
}
#content .box-gear .box .cart{
    margin-top: 7px;
    overflow: hidden;
    line-height: 30px;
    //font-size: 0.95em;
}
.home #content .box-gear .box .cart .status,
.home #content .box-gear .box .cart .btn{
    float: none;
}

#content .box-gear .box .cart .status span{
    color: #0070BA;
}
#content .box-gear .box .cart .icon{
    background: url(img/icon.png) -194px -217px no-repeat;
    padding: 2px 9px;
    margin-right: 3px;
}
#content .box-gear .box .cart .status .stock{
    color: #079b00;
}
#content .box-gear .box .cart .stock .icon{
    background-position: -223px -214px;
}
#content .box-gear .box .cart .btn{
    margin: 0;
    padding: 0;
}
#content .box-gear .box .cart .btn .icon{
    background-position: -253px -213px;
}
#content .box-gear .box .cart .btn a{
    color: #333333;
    text-transform: none;
    font-weight: normal;
}
#content .box-gear .box .cart .btn a:hover{
    text-decoration: none;
    color: #0070ba;
}


.detail-gear  #related-item{
    margin: 30px 0;
}
#related-item .title{
    font-weight: bold;
}
#related-item .box-gear .box{
    width: 16.666%;
}
#related-item .box-gear .thumb{
    text-align: center;
}

/**** .tooltip-gear ***/
#content .box-gear .box .tooltip-gear {
    /* by default, hide it */
    display: none;
    width: 380px;
    background: #ffffff;
    border: 1px solid #aaaaaa;
    /* allow us to move the tooltip */
    position: absolute;
    /* align the image properly */
    box-shadow: 0 0 3px #aaaaaa;
    border-radius: 3px;
    overflow: hidden;
}
@media screen and (max-width: 500px) {
    #content .box-gear .box .tooltip-gear{
        display: none !important;
    }
}
#content .box-gear .box .tooltip-gear .title-t{
    background-color: #0070BA;
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    padding: 5px;
    text-align: center;
    box-shadow: 0 1px 2px #aaaaaa;
}
#content .box-gear .box .tooltip-gear .c{
    padding: 10px;
}
#content .box-gear .box .tooltip-gear .row{
    margin: 2px 0;
}
#content .box-gear .box .tooltip-gear .row.summary{
    border-top: 1px solid #eeeeee;
    margin-top: 10px;
    padding-top: 10px;
    text-align: justify;
}
#content .box-gear .box .tooltip-gear ul{
    list-style: none;
}
#content .box-gear .box .tooltip-gear .row .t{
    font-weight: bold;
    color: #777777;
    display: block;
    margin-right: 10px;
}



/**** .list-gear ****/
.style-larger #main,
.style-larger #header,
.style-larger #footer .container,
.style-larger #footer .c{
    width: 98%;
    max-width: 1500px;
}
.style-larger #main{
    margin-top: 10px !important;
}





/*** .sidebar .factory ***/
.sidebar .factory ul li{
    line-height: 20px;
}
.sidebar .factory a{
    color: #666666;
}
.sidebar .factory a:hover{
    color: #0070BA;
}
.sidebar .factory ul li.current a{
    color: #0070ba;
    font-weight: 600;
}
.sidebar .factory ul.hide_not_current li{
    display: none;
}
.sidebar .factory ul.hide_not_current li.current{
    display: block;
}
.sidebar .factory li .icon{
    background: url(img/icon.png) -125px -218px no-repeat;
    padding: 1px 8px;
    margin-right: 5px;
}
.sidebar .factory li.current .icon{
    background-position: -166px -218px;
}


/*** .gear-category ****/
.sidebar .gear-category ul{
    padding-left: 13px;
}
.sidebar .gear-category li{
    line-height: 20px;
    padding: 4px 0;
    list-style: disclosure-closed;
    color: #555555;
}
.sidebar .gear-category li a{
    color: #333333;
}
.sidebar .gear-category li:hover,
.sidebar .gear-category li a:hover{
    color: #0070ba;
}
.sidebar .gear-category li.current,
.sidebar .gear-category li.current a{
    color: #0070ba;
    font-weight: 600;
}


/**** .detail-image ****/
.detail-image{
    width: 35%;
    overflow: hidden;
    float: left;
}
.detail-image .img-responsive{
    width: 100%;
}
.detail-image ul{
    list-style: none;
}
.detail-image .small-image{
    width: 14%;
    float: left;
}
.detail-image .small-image li{
    border: 1px solid #eeedf2;
    margin-bottom: 3px;
    line-height: 1px;
    padding: 2px;
}
.detail-image .small-image li.current,
.detail-image .small-image li:hover{
    border-color: #1B95E0;
}
.detail-image .larger-image{
    border: 1px solid #eeedf2;
    float: right;
    width: 82%;
}
.detail-image .larger-image li{
    display: none;
    line-height: 1px;
}
.detail-image .larger-image li.current{
    display: block;
}
.detail-image .larger-image li:hover{
    cursor: pointer;
}


/*** table.together ***/
.row.together{
    margin-top: 25px !important;
}
.row.together a{
    color: #333333;
}
.row.together .t{
    font-size: 1.1em !important;
}
.row.together .box{
    padding: 0 20px 20px;
    overflow: auto;
    height: 352px;
    margin-top: 5px;
    background: #f7f7f7;
}
.together table{
    margin-top: 20px;
    width: 100%;
}
.together table tr{
    border-bottom: 1px solid #eeeeee;
}
.together table tr:last-of-type{
    border-bottom: none;
}
.together table td{
    vertical-align: middle;
    padding-top: 5px
}
.together table .small{
    width: 1px;
    white-space: nowrap;
}
.together table .thumb{
    padding-right: 10px;
    padding-left: 10px;
}
.together table .thumb img{
    line-height: 1px;
    width: 50px;
}
.together table .price{
    text-align: right;
}
.together table .price .old{
    text-decoration: line-through;
    color: #9999;
}
.together table .price .new{
    color: #ff424e;
}


/*** .description2 ***/
#content .description2{
    overflow: hidden;
    margin-top: 40px;
}
#content .description2 .col-left{
    float: left;
    width: 70%;
}

#content .description2 .col-right{
    width: 30%;
    float: right;
    padding-left: 20px;
}
#content .description2 .col-right .box{
    border: 1px solid #dddddd;
    margin-bottom: 20px;
    border-radius: 7px 7px 0 0;
}
#content .description2 .col-right span.t{
    background-color: #0070BA;
    color: #ffffff;
    display: block;
    text-align: center;
    padding: 10px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 5px 5px 0 0;
}
#content .description2 .col-right .box .c{
    padding: 10px;
    line-height: 28px;
    text-align: justify;
}
#content .description2 .col-right ul{
    list-style-position: inside;
}


/***** .detail-gear *****/
.detail-gear .info-gear {
    width: 63%;
    float: right;
}

.detail-gear #main .info-gear .title{
    margin-bottom: 0 !important;
    border: none;
    background-color: transparent;
    padding: 0;
    font-family: 'OpenSans-SemiBold';
}
.detail-gear #main .info-gear .title h1{
    font-size: 24px !important;
    text-transform: none;
    margin-bottom: 12px;
    font-weight: normal;
}

.detail-gear .info-gear .cart-button {
    margin-top: 20px;
    overflow: hidden;
}
.detail-gear .info-gear .cart-button .btn{
    background-color: #48CD89;
    color: #ffffff;
    height: 40px;
    line-height: 28px;
    width: 300px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}
.detail-gear .info-gear .cart-button .btn:hover{
    background-color: #3fbf7d;
}
.detail-gear .info-gear .cart-button .now{
    background-color: #FF0013;
    border-color: #FF0013;
    float: right;
}
.detail-gear .info-gear .cart-button .now:hover{
    color: #FF0013;
    background-color: #ffffff;
}

.detail-gear .info-gear .row{
    margin: 3px 0;
    overflow: hidden;
    padding: 3px 0;
    text-align: justify;
}
.detail-gear .info-gear .row.price small{
    font-size: 14px;
}
.detail-gear .info-gear .row.stock{
    color: #079b00;
}
.detail-gear .info-gear .row.status{
    color: #FF0013;
}
.detail-gear .info-gear .row.promotion{
    color: #ff424e;
}
.detail-gear .info-gear .row .t{
    color: #888888;
    font-weight: bold;
    margin-right: 10px;
    font-size: 0.9em;
}
.detail-gear .info-gear .price .c{
    font-weight: bold;
    font-size: 25px;
    color: #ff424e;
}
.detail-gear .info-gear .price_mccash .c{
    color: #ff424e;
    font-size: 15px;
    font-weight: bold;
}
.detail-gear .info-gear .p{
    color: #ff424e;
}

.detail-gear ul.nav-tabs {
    margin-top: 20px;
}

.detail-gear .row ul{
    list-style: none;
}



/******* .paginate-mobile *********/
ul.paginate-mobile {
    display: none;
    overflow: hidden;
    margin-top: 10px;
}

ul.paginate-mobile li {
    width: 45%;
    background: #4485f5;
    border: 1px solid #407fe5;
    float: left;
    display: block;
    border-radius: 15px;
}

ul.paginate-mobile li:hover {
    background-color: #2b7ef2;
    border: 1px solid #1f74e2;
}

ul.paginate-mobile li.disabled,
ul.paginate-mobile li.disabled:hover {
    background-color: #aaa;
    border: 1px solid #9E9E9E;
}

ul.paginate-mobile li:last-of-type {
    float: right;
}

ul.paginate-mobile li a {
    color: #fff;
    display: block;
    text-align: center;
    padding: 3px 0;
}

ul.paginate-mobile li a:hover {
    text-decoration: none;
}

/******* .pagination *********/
ul.pagination {
    list-style: none;
    margin: 10px 0 20px 0;
    padding: 0;
    overflow: hidden;
    float: right;
}

ul.pagination li {
    float: left;
    margin-left: 2px;
    height: 25px;
    line-height: 25px;
}

ul.pagination li a {
    padding: 3px 6px;
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    font-size: 14px;
}

ul.pagination li a:hover {
    text-decoration: none;
}

ul.pagination li.current a,
ul.pagination li a:hover {
    background-color: #a9a9a9;
    border: 1px solid #a2a2a2;
    color: #fff;
}

@media all and (max-width: 450px) {
    ul.paginate-mobile {
        display: block;
    }
    #content .pagination{
        display: none !important;
        background-color: #ff424e;
    }
}

@media (max-width:1500px) {
    #related-item .box-gear .box{
        width: 20%;
    }
}
@media (max-width:1200px) {
    .style-larger #main,
    .style-larger #header,
    #footer .container{
        width: 100%;
    }

    #related-item .box-gear .box{
        width: 25%;
    }
}

@media (max-width:1200px) {
    .style-larger #main,
    .style-larger #header,
    .style-larger #footer .container,
    .style-larger #wrapper{
        width: 99%;
    }

    #content .box-gear .box{
        width: 25%;
    }

    .detail-image{
        width: 42%;
    }
    .detail-gear .info-gear{
        width: 55%;
    }
}
@media (max-width:950px) {
    #content .box-gear .box{
        width: 33.33%;
    }
}

@media (max-width:750px) {
    #content .description2 .col-left{
        width: 100%;
    }
    #content .description2 .col-right{
        display: none;
    }
    .detail-image,
    .detail-gear .info-gear{
        width: 100%;
        margin-bottom: 20px;
    }
    #content .box-gear .box{
        width: 50%;
    }

    .home #content .box-gear .box{
        width: 33.33%;
    }
}

@media (max-width:600px) {
    #content .box-gear .box{
        .bg-box{
            padding: 0;
        }
        .price,.name{
            text-align: center;
        }
        .status, .stock,.btn{
            width: 100%;
            display: block;
            float: none;
            text-align: center;
        }
    }

    .home #content .box-gear .box{
        width: 50%;
    }
    #content .box-gear .box .new{
        font-size: 1em;
    }
}