// Lên đầu trang
#back-top {
    bottom: 17px;
    right: 85px;
    position: fixed;
    z-index: 999;

    // @media all and (max-width: 768px) {
    //   bottom: 64px;
    // }

    // @media screen and (max-width: 500px) {
    //     bottom: 104px;
    // }


    a {
        display: inline-block;
        text-align: center;
        background-color: white;
        width: 50px;
        height: 50px;
        border-radius: 100%;

        &:hover {
            color: black;

            .fa-chevron-circle-up {
                color: darken($primary-color,5%);
            }
        }

        .fa-chevron-circle-up {
            color: $primary-color;
            font-size: 50px;
        }
    }
}