/**** .login ****/
@font-face {
  font-family: 'Roboto-Medium';

  src: url('fonts/Roboto-Medium.eot');
  src: url('fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'), url('fonts/Roboto-Medium.woff2') format('woff2'), url('fonts/Roboto-Medium.woff') format('woff'), url('fonts/Roboto-Medium.ttf') format('truetype'), url('fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
}

.login {
    a{
        color: $text-link;
    }

    .fa {
        color: #666;

        &.fa-hourglass-start {
            font-size: 12px;
        }
    }
    label{
        margin-bottom: 5px;
        font-weight: normal;
    }

    .c {
        padding-top: 5px;
    }

    ul {

        li{
            padding: 10px 0;

            &.name {
                a{
                    color: $text-link;
                }
            }


        }



        a {
            color: #333;

            &:hover{
                text-decoration: underline;
            }

            &.right{
                float: right;
                color: #0882e8;
            }
        }
    }

    .btn{
        width: 100%;
        background-color: $button_1;
        border: none;
        text-transform: none;

        &:hover, &:focus{
            background-color: darken($button_1, 5%);
        }
    }

    .login-by-social{
        text-align: center;

        span {
            font-size: 13px;
        }

        ul {
            margin:  10px 0;

            li {
                padding:  0;
                border: none;

                &:last-of-type{
                    padding-top: 5px;
                }
            }
        }

        .btn {
            height: 30px;
            line-height: 20px;
            color: #fff;
            font-family: Roboto-Medium;
            padding:  1px;
            margin: 0;
            font-weight: normal;
            display: inline-block;
        }

        .btn-google-plus{
            background-color: $btn_google_plus;

            svg {
                float: left;
                background-color: #fff;
                width: 20px;
                height: 20px;
            }
        }

        .btn-facebook-login {
            background-color: $btn_facebook;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
            margin-top: 5px;

            svg {
                width: 26px;
                float: left;
            }

            &:hover {
                -webkit-box-shadow: 0 0 3px 3px rgba(66,103,178,.3);
                box-shadow: 0 0 3px 3px rgba(66,103,178,.3);
            }
        }
    }

    .forget{
        text-align: center;
        font-size: 11px;
        font-style: italic;
        margin: 5px 0 10px;

        a{
            &:hover{
                text-decoration: underline;
            }
        }
    }

    .registration{
        margin: 15px auto 5px;
        width: 90%;
        border-top: 1px solid #ddd;
        padding-top: 10px;
        text-align: center;
        font-weight: bold;

        a{
            font-size: 13px;

            &:hover{
                font-style: italic;
            }
        }
    }
}

#sidebar-left {

    .login {
        ul {
            li {
                padding: 8px 0;
            }
        }
    }
}