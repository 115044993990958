/******* .box-video ********/
#content .box-video {
    margin-top: 0 !important;
}

#content .box-video .box {
    float: left;
    width: 25%;
    padding: 0 2px;
    margin: 0 0 12px 0;
}

#content .box-video .box .bg-box {
    border: 1px solid #eee;
    background-color: #fff;
}

#content .box-video .box .bg-box:hover {
    border: 1px solid #999;
}

#content .box-video .box .name {
    height: 22px;
    overflow: hidden;
    padding: 0 3px;
    text-align: center;
    margin: 2px 0 4px;
}

#content .box-video .box .name a {
    color: #333;
}

#content .box-video .box .name a:hover {
    text-decoration: none;
}

#content .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

#content .video-container iframe, #content .video-container object, #content .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
