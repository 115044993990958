/***** .box-social *****/
.icon_social{
    background-image: url(img/login_social.png);
    background-repeat: no-repeat;
}

.box-social{
    padding: 0 10px;
    margin-top: 15px;

    .label{
        float: left;
        font-weight: bold;
        margin: 17px 10px 0 5px;
    }

    #rrssb-buttons {
        ul.rrssb-buttons {
            margin: 10px 0;

            li {
                .social {
                    margin-left: 0;
                }
            }
        }
    }
}

/*** .social ****/
ul{
    &.social {
        margin: 10px 0;
        padding: 0;
        list-style: none;
        overflow: hidden;

        div {
            margin: 0;
        }

        li {
            display: block;
            float: left;
            margin-bottom: 3px;

            &.facebook {
            }

            &.twitter {
            }

            &.pinterest {
                margin-right: 10px;
            }

            &.linkedin {
                margin-right: 10px;
            }
        }

    }
}

.fa-zalo {
    font-family: arial;
    font-weight: 800;
}
/**** .social-alway-top *****/
.social-always-on-top{
    position: fixed;
    right: 0;
    z-index: 1000 !important;
    background-color: #fff;
    border-right: 0;
    padding: 10px 5px 0;

    @media screen and (min-width: 751px) {
        top: 200px;
        box-shadow: -1px 0 3px #999;
        border-radius: 5px 0 0 5px;
        padding-right: 5px;
    }
    @media screen and (max-width: 750px) {
        top: 40px;
        width: 100%;
        padding-top: 5px;
        border-bottom: 1px solid #ccc;
    }

    &:hover{
        box-shadow: -1px 0 3px #555;

        // a.close {
        //     display: block;
        // }
    }

    ul{
        margin: 0 auto;
        padding: 0;
        list-style: none;
        text-align: center;

        @media screen and (min-width: 751px) {
            width: 107px;
        }

        li{
            float: initial;
            display: inline-block;
            margin-bottom: 5px;

            @media screen and (min-width: 500px) and (max-width: 768px) {
                > div,
                iframe {
                    float: left!important;
                    margin-bottom: 2px!important;
                }
            }

            @media screen and (min-width: 320px) and (max-width: 500px) {
                > div,
                iframe {
                    float: left!important;
                    margin-bottom: 2px!important;
                }
            }
        }
    }

    // a.close {
    //     display: none;
    //     position: absolute;
    //     top: 12px;
    //     left: -13px;
    //     background-color: white;
    //     border: 1px solid #ccc;
    //     padding: 2px;
    //     border-right: 0;
    // }
}


#footer .fb-like{
    margin-top: 12px !important;
}

/*** .chat-zalo ***/
.chat-zalo{
    position: fixed;
    bottom: 89px;
    right: 24px;
    z-index: 10000;
    box-shadow: 0 0 3px #aaaaaa;
    border-radius: 40px;
    line-height: 1px;

    &:hover{
        box-shadow: 0 0 3px #888888;
    }
}