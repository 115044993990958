/**** .contact ****/
.contact {
    #content {
        address {
            margin-bottom: 20px;
        }

        .address {
            .fa {
                font-size: 17px;
                color: $primary-color;

                &.fa-envelope {
                    font-size: 14px;
                }

                &.fa-zalo {
                    font-size: 15px;
                }
            }

            p {
                span {
                    color: $primary-color;
                }
             }
        }

        .c {
            background-color: #ffffff;
            border: 1px solid $border-color-normal;
            padding: 10px;

            /*.form {

                &.transfer_form {
                    @media screen and (min-width: 1200px) {
                        .input {
                            width: 84%;
                        }

                        label {
                            width: 16%;
                        }
                    }

                }
                input.name {
                    padding: 0 5px;
                }

                label {
                    @media screen and (min-width: 1200px) {
                        float: left;
                        width: 20%;
                    }
                }

                .input {

                    @media screen and (min-width: 1200px) {
                        float: left;
                        width: 80%;
                    }
                }

                .captcha {
                    @media screen and (min-width: 1200px) {
                        float: left;
                        width: 100%;
                    }

                    label {
                        margin-top: 20px;
                        margin-right: 5px;

                        @media screen and (min-width: 1200px) {
                            float: left;
                            width: 25%;
                        }

                        @media screen and (max-width: 500px) {
                            margin-top: 0;
                        }
                    }

                    #inner_captcha {
                        float: left;

                        @media screen and (max-width: 500px) {
                            width: 100%;
                            text-align: center;
                        }
                    }

                    #img_captcha {
                        @media screen and (min-width: 1200px) {
                            float: left;
                        }

                        img {
                            width: 200px;
                            display: inline-block;
                        }
                    }

                    .input {
                        float: left;
                        width: 29%;
                        margin-top: 20px;

                        @media screen and (min-width: 768px) {
                             margin-right: 15px;
                        }

                        @media screen and (max-width: 560px) {
                            width: 122px;
                            margin-right: 8px;
                        }

                        @media screen and (min-width: 560px) and (max-width: 768px){
                            width: 165px;
                            margin-right: 11px;
                        }
                        @media screen and (max-width: 500px) {
                            width: 182px;
                            margin-top: 0;
                        }

                        @media screen and (max-width: 375px) {
                            width: 100%;
                        }

                    }
                }
            }*/
        }

        .notification{
            font-style: italic;
            color: red;
            margin-bottom: 15px;
        }

        .submit{
            text-align: center;
            margin: 15px 0;
            display: inline-block;
            width: 100%;
        }
    }
}

/*** #googleMap ***/
.map {
    border-radius: 5px;
    overflow: hidden;
    margin-top: 20px;
    border: 3px solid #ddd;
}

#googleMap {
    width: 100%;
    height: 250px;
}