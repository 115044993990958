.instruction{
	position: absolute;
    background-color: white;
    padding: 13px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 3px #ccc;

    &::before{
        content: '';
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid #ccc;
        width: 10px;
        height: 10px;
        position: absolute;
        top: -10px;
    }

    &::after{
        content: '';
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid #fff;
        width: 11px;
        height: 11px;
        position: absolute;
        top: -10px;
        left: 13px;
    }

    button{
        text-align: center;
        height: 33px;
        border: 1px solid;
        border-color: #c89411 #b0820f #99710d;
        -webkit-border-radius: 3px 3px 3px 3px;
        -moz-border-radius: 3px 3px 3px 3px;
        border-radius: 3px 3px 3px 3px;
        border-radius: 0\9;
        -webkit-box-shadow: inset 0 1px 0 0 #fcf3dc;
        -moz-box-shadow: inset 0 1px 0 0 #fcf3dc;
        box-shadow: inset 0 1px 0 0 #fcf3dc;
        background: #FFE700;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFF8E3AD', endColorstr='#FFEEBA37', GradientType=0 );
        background: linear-gradient(to bottom,#fff8b3,#FFE700);
        background: -moz-linear-gradient(top,#fff8b3,#FFE700);
        background: -webkit-linear-gradient(top,#fff8b3,#FFE700);
        background: -o-linear-gradient(top,#fff8b3,#FFE700);
        background: -ms-linear-gradient(top,#fff8b3,#FFE700);

        &:focus, &:hover {
            background: #ffee4c;
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFF6DA95', endColorstr='#FFECB21F', GradientType=0 );
            background: linear-gradient(to bottom,#fff8b3,#ffee4c);
            background: -moz-linear-gradient(top,#fff8b3,#ffee4c);
            background: -webkit-linear-gradient(top,#fff8b3,#ffee4c);
            background: -o-linear-gradient(top,#fff8b3,#ffee4c);
            background: -ms-linear-gradient(top,#fff8b3,#ffee4c);
            text-decoration: none;
        }
    }
}