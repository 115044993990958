


.style-larger {

    @media screen and (max-width:750px) {
        #main{
           padding-top: 30px;
       }
    }

    .order-information {
        &.thanks{
            #main {
                .title{
                    margin-bottom: -1px;
                }
            }
        }

        #main {

            .title{
                margin-bottom: 20px;
            }
        }

        label{
            float: none;
        }

        .s-name{
            position: relative;
            height: 30px;

            div{
                position: absolute;
                top: 0;
                left: 0;
            }

            .name{
                width: 100%;

                input{
                    padding-left: 80px;
                }
            }
        }

        .submit{
            text-align: center;
            margin-top: 20px;

            input{
                background-color: #0070BA;
                color: #ffffff;
                margin: 0 auto;
                width: 180px;

                &:hover{
                    background-color: #0165a3;
                }
            }
        }
    }
}