/***** .newsletter *****/
.newsletter {
    .title {
        display: block;
        margin-bottom: 15px;
        font-weight: bold;
    }

    .des {
        font-size: 0.9em;
        color: #777;
        margin-bottom: 15px;
    }

    input {
        float: left;

        &.email {
            background-color: #fff;
            margin-right: -100px;
            float: left;
        }
    }

    .btn {
        margin: 0 0 0 3px;
        height: 30px;
        line-height: 24px;
        float: right;
    }
}