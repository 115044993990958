[class*="icon-"]{
    background-image: url(img/icon.png);
    background-repeat: no-repeat;
}
/**** .icon-small ****/
#content {

    .icon-small {
        color: #666;
        font-style: italic;
        overflow: hidden;
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        div, p {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }

        .view,
        .datetime {
            float: left;
            margin-right: 15px;
        }

        .view span,
        .datetime span {
            background-image: url(img/icon.png);
            background-repeat: no-repeat;
            padding: 0 10px;
        }

        .view span {
            background-position: -91px -31px;
        }
    }

}






