input, select {
    height: 30px;
}
.img-fluid{
    height: auto !important;
}

input:focus, textarea:focus, select:focus {
    box-shadow: 0 0 2px #C4C4C4;
    background: #fff;
}

input, textarea, select {
    border: 1px solid $border-color-normal;
    padding: 0 5px;
    border-radius: 2px;
}

span.error{
    color: $text-error;
    margin-top: -10px;
    margin-bottom: 5px;
    display: block;
    font-style: italic;
    font-size: 0.9em;
    display: inline-block;
}

.error {
    input, label {
        color: $text-error;;
    }
}

textarea {
    padding: 5px 6px;
}

select {
    padding-left: 2px;
    color: $text-color-normal;
}


input.larger, input.name {
    width: 100%;
}

input.name {
    /*padding-left: 75px;*/
}

input.medium {
    width: 70%;
}

input.small {
    width: 37%;
}

select.gender {
    width: 70px;
    float: left;
    margin-right: -75px;
    position: relative;
    z-index: 1;
}

select.larger {
    width: 100%;
}

select.medium {
    width: 70%;
}

select.small {
    width: 30%;
}

textarea.larger {
    width: 100%;
}

.date {
    .input {
        margin: 0px;
    }
}

.gender {
    .input {
        margin: 0px;
    }
}
.method-line{
    overflow: hidden;

    .col-l{
        float: left !important;
    }
    div{
        float: right;

        label{
            color: red;
            font-weight: bold;
            line-height: 30px;
        }
        input{
            margin-bottom: 0 !important;
        }
    }
}
.order-information .radio{
    background-color: #e5f5ff;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid #d4ecfc;
    margin-bottom: 15px;

    input{
        margin-bottom: 2px !important;

        &:focus{
            box-shadow: none !important;
        }
    }
}
.address{
    .col-l{
        float: left !important;
    }
    .col-r{
        float: right;

        a{
            line-height: 30px;
            font-style: italic;
        }
    }
}
label {
    display: block;
    font-weight: bold;
    font-size: 13px;
    color: #555;
    float: left;
    height: 30px;
    line-height: 30px;
}

.form-group {
    float: left;
    width: 100%;
    margin-bottom: 10px;

    .datetime {

        @media screen and (max-width:1200px) {
            width: 100%;
            overflow: hidden;
        }

        div.select {
            float: left !important;
            width: 100px !important;
            margin-right: 5px;

            @media screen and (max-width:400px) {
                width: 30% !important;
                overflow: hidden;
            }
        }
    }

}

.form-control {
    display: block;
    width: 100%;
    height: 32px;
    /*padding: 0 5px;*/
    font-size: 14px;
    line-height: 1.42857143;
    color: $text-color-normal;
    background-color: #fff;
    background-image: none;
    border: 1px solid $border-color-normal;
    border-radius: 2px;

    &:focus {
        box-shadow: none;
    }

    &.disabled {
        background-color: #eee;
        cursor: no-drop;
    }
}

textarea.form-control {
    height: auto;
}

.box-fields {
    margin-top: 10px;

    @media screen and (max-width: 768px) {

        &.related {
            .c {
                margin: 0 10px;
            }
        }
    }

    .c {

      .textarea .form-control {
            height: 32px;
        }
    }
}

textarea.error,
input.error {
    border: 1px solid $text-error;
}


// .transfer_form {
//     ::-webkit-input-placeholder { /* Chrome */
//       color: red;
//     }
//     :-ms-input-placeholder { /* IE 10+ */
//       color: red;
//     }
//     ::-moz-placeholder { /* Firefox 19+ */
//       color: red;
//       opacity: 1;
//     }
//     :-moz-placeholder { /* Firefox 4 - 18 */
//       color: red;
//       opacity: 1;
//     }
// }

.password-confirm {
    .gender {
        float: left;
        position: absolute;
        margin: 15px 0px 0;
    }
}

select.form-control:not([size]):not([multiple]) {
    height: 32px!important;
}