.btn {
    border-radius: 0;
    border: none;
    height: 30px;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    /*margin: 2px;*/

    &:hover, &:focus {
        cursor: pointer;
        text-decoration: none;
        box-shadow: none;
    }

    &.btn-larger {
        width: 220px;
        font-size: 15px;
    }

    &.btn-medium {
        width: 150px;
        font-size: 13px;
    }

    &.btn-small {
        width: 90px;
        line-height: 15px;
    }


    img {
        vertical-align: text-bottom;
    }
}

a.btn-orange {
    &:hover, &:focus {
        color: white;
    }
}

.btn-orange {
    color: $text-white;
    background-color: $button;

    &:hover, &:focus {
        background-color: darken($button, 5%);
    }
}
.btn-primary {
    background-color: $primary-color;
}

.btn-primary.disabled, .btn-primary:disabled {
    background-color: $button;
    border-color: $button;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background-color: $button;
    border-color: $button;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-lock {
    margin: 0 auto;
    color: white;
    background-color: #555;

    &:hover, &:focus {
        color: white;
    }
}

.btn-banking {
    line-height: 30%;
}

a {
    &.btn {
        display: block;

        &.larger {
            width: 220px;
            font-size: 15px;
        }

        &.medium {
            width: 150px;
            font-size: 13px;
        }

        &.small {
            width: 90px;
        }
    }
}

#btn_checkout {
    display: inline-block;

    &:hover{
        color: white;
    }
}

.btn-green {
    background-color: $button_1;
    border: 1px solid $button_1;
    color: white;

    &:hover, &:focus{
        background-color: darken($button_1, 5%);
        background-color: darken($button_1, 5%);
        border: 1px solid darken($button_1, 5%);
    }
}

a.btn-support {
    height: 20px;
    line-height: 10px;
    font-size: 0.9em;
    color: white;
    text-transform: none;
    background-color: $primary-color;
	width: 107px;
    text-transform: uppercase;

    &:hover, &:focus {
        color: white;
    }


    @media screen and (max-width: 768px) {
        float: left;
        margin: 2px 0;
    }

    @media screen and (min-width: 1200px) {
        line-height: 16px;
        height: 27px;
    }

    .fa {
        font-size: 23px;
        float: left;
        padding: 5px 0 0 6px;
    }

    &:hover, &:focus {
        background-color: darken($primary-color, 5%);
		color: #FFF;
    }
}


a.btn-hotline {
    background-color: #F7392E;/*$button_1;*/
    line-height: 21px;
    height: 20px;
    color: white;
    text-align: left;
    width: 107px;

    @media screen and (min-width: 1200px) {
        line-height: 27px;
        height: 27px;
    }

    @media screen and (max-width: 768px) {
        float: left;
        margin: 2px 0;
        padding: 0 5px;
    }

    @media screen and (min-width: 750px) {
        padding: 0 5px;
        margin: 0;
    }

    @media screen and (max-width: 375px) {
        padding: 0 3px;
        margin: 2px 0;
    }

    &:hover, &:focus {
        background-color: darken($button_1, 5%);
        color: white;
    }

    .fa {
        margin-right: 3px;
        margin-top: 4px;

        @media screen and (min-width: 768px) {
            float: left;
            margin: 5px 2px 0 2px;
        }

        @media screen and (min-width: 1200px) {
            margin: 8px 2px 0 2px;
        }
    }

    span {
        display: inline-block;
        white-space: nowrap;
    }
}

@media screen and (max-width: 576px) {
    .btn-sm-medium {
        width: 150px!important;
    }
}

.btn_copy{
    background: url(img/icon.png) -118px -255px no-repeat;
    padding: 1px 15px!important;

    &:hover{
        cursor: pointer;
    }
}

a.btn-copy-orderid {
    position: absolute;
    top: 30%;
    right: -9px;
}